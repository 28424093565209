<template>

  <div class="Panel bg-white">

    <NavBar
        :user="user"
    />

    <div class="">

      <b-row>

        <b-col cols="12" lg="2" class="p-3" style="border-right: 1px solid #e9e9e9;background-color: #f9f9f9;">

          <b-list-group>
            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel'">
              <span class="material-symbols-outlined align-middle fw-600 icon" style="color:#585bff;">apps</span>
              Aplikacje
            </b-list-group-item>

            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel/kursy'">
              <span class="material-symbols-outlined align-middle fw-600 icon">menu_book</span>
              Kursy
            </b-list-group-item>
          </b-list-group>

        </b-col>

        <b-col cols="12" lg="10" class="p-3 pt-4">

          <div v-if="!isReady" class="text-center">
           Ładowanie...
          </div>

          <div v-if="isReady">

            <h5 class="text-dark">
              <b-button variant="light" size="sm" :to="'/panel'">
                <span class="material-symbols-outlined">reply</span>
              </b-button> {{ app.title }}
            </h5>

            <hr/>

            <AppCopywriterAi v-if="(slug === 'copywriter-ai')" />
            <AppFreelanceGraphicAi v-if="(slug === 'freelance-graphic-ai')" />
            <AppInstagramAudytorAi v-if="(slug === 'instagram-audytor-ai')" />
            <AppImageToPostAi v-if="(slug === 'image-to-post-ai')" />
            <AppHashtagGeneratorAi v-if="(slug === 'hashtag-generator-ai')" />
            <AppMidjourneyPromptGenerator v-if="(slug === 'midjourney-prompt-generator')" />
            <AppPixelAi v-if="(slug === 'pixel-ai')" />
            <AppVisualAi v-if="(slug === 'visual-ai')" />
            <AppPixelEditor v-if="(slug === 'pixel-editor')" />
            <AppVideoAvatarAi v-if="(slug === 'video-avatar-ai')" />
            <AppSmartAssistanceAi v-if="(slug === 'smart-assistance-ai')" />
            <AppHeadlinerAi v-if="(slug === 'headliner-ai')" />
            <AppInspiringPostAi v-if="(slug === 'inspiring-post-ai')" />
            <AppTextEditorAi v-if="(slug === 'text-editor-ai')" />
            <AppContentGeniusAi v-if="(slug === 'content-genius-ai')" />
            <AppQAAiGenerator v-if="(slug === 'qa-ai-generator')" />
            <AppContentWriterAi v-if="(slug === 'content-writer-ai')" />
            <AppBillionerMentoringAi v-if="(slug === 'billioner-mentoring-ai')" />
            <AppBiznesCoachAi v-if="(slug === 'biznes-coach-ai')" />
            <AppArticleBoostAi v-if="(slug === 'article-boost-ai')" />
            <AppSeoContentAi v-if="(slug === 'seo-content-ai')" />
            <AppSocialAppAi v-if="(slug === 'social-app-ai')" />
            <AppInfluAiGenerator v-if="(slug === 'influ-ai-generator')" />
            <AppReelsKeywordsAi v-if="(slug === 'reels-keywords-ai')" />
            <AppOdbierzStatuetke v-if="(slug === 'odbierz-statuetke')" />


          </div>

        </b-col>

      </b-row>

    </div>

    <Footer/>

  </div>

</template>

<script>

import Footer from '@/elements/Footer';
import NavBar from "@/elements/Navbar";

import AppCopywriterAi from "@/pages/Panel/App/copywriter-ai";
import AppFreelanceGraphicAi from "@/pages/Panel/App/freelance-graphic-ai";
import AppInstagramAudytorAi from "@/pages/Panel/App/instagram-audytor-ai";
import AppImageToPostAi from "@/pages/Panel/App/image-to-post-ai";
import AppHashtagGeneratorAi from "@/pages/Panel/App/hashtag-generator-ai";
import AppMidjourneyPromptGenerator from "@/pages/Panel/App/midjourney-prompt-generator";
import AppPixelAi from "@/pages/Panel/App/pixel-ai";
import AppVisualAi from "@/pages/Panel/App/visual-ai";
import AppPixelEditor from "@/pages/Panel/App/pixel-editor";
import AppVideoAvatarAi from "@/pages/Panel/App/video-avatar-ai";
import AppSmartAssistanceAi from "@/pages/Panel/App/copywriter-ai";
import AppHeadlinerAi from "@/pages/Panel/App/headliner-ai";
import AppInspiringPostAi from "@/pages/Panel/App/inspiring-post-ai";
import AppTextEditorAi from "@/pages/Panel/App/text-editor-ai";
import AppContentGeniusAi from "@/pages/Panel/App/content-genius-ai";
import AppQAAiGenerator from "@/pages/Panel/App/qa-ai-generator";
import AppContentWriterAi from "@/pages/Panel/App/content-writer-ai";
import AppBillionerMentoringAi from "@/pages/Panel/App/billioner-mentoring-ai";
import AppBiznesCoachAi from "@/pages/Panel/App/biznes-coach-ai";
import AppArticleBoostAi from "@/pages/Panel/App/article-boost-ai";
import AppSeoContentAi from "@/pages/Panel/App/seo-content-ai";
import AppSocialAppAi from "@/pages/Panel/App/social-app-ai";
import AppInfluAiGenerator from "@/pages/Panel/App/influ-ai-generator";
import AppReelsKeywordsAi from "@/pages/Panel/App/reels-keywords-ai";
import AppOdbierzStatuetke from "@/pages/Panel/App/odbierz-statuetke";



export default {

  name: 'PanelApp',

  components: {
    NavBar,
    Footer,
    AppCopywriterAi,
    AppFreelanceGraphicAi,
    AppInstagramAudytorAi,
    AppImageToPostAi,
    AppHashtagGeneratorAi,
    AppMidjourneyPromptGenerator,
    AppPixelAi,
    AppPixelEditor,
    AppVideoAvatarAi,
    AppVisualAi,
    AppSmartAssistanceAi,
    AppHeadlinerAi,
    AppInspiringPostAi,
    AppTextEditorAi,
    AppContentGeniusAi,
    AppQAAiGenerator,
    AppArticleBoostAi,
    AppBiznesCoachAi,
    AppBillionerMentoringAi,
    AppContentWriterAi,
    AppSeoContentAi,
    AppSocialAppAi,
    AppInfluAiGenerator,
    AppReelsKeywordsAi,
    AppOdbierzStatuetke
  },

  data() {

    return {
      slug: this.$route.params.slug,
      isReady: false,
      app: {},
      user: {
        id: null,
        permissions: []
      }
    }

  },

  created() {

    this.axios.get(window.API_URL + "user", { withCredentials: true }).then((response) => {

      let data = response.data

      if(data.redirectTo) {

        top.location.href = data.redirectTo;

      }

      if(data.userId) {

        this.user.id = data.userId
        this.user.permissions = data.permissions

        this.axios.get(window.API_URL + "apps/getApp/" + this.slug).then((response) => {

          this.app = response.data;

          if (!this.user.permissions.includes(this.app.neededPermissions)) {

            this.$router.push('/access-denied')

          } else {

            this.isReady = true

          }

        });


      }

    });

  },

  methods: {


  },

  watch: {

    '$route.params.slug' (slug) {

      this.slug = slug

    }

  },


}

</script>