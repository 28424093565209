<template>
  <div class="bg-white">

    <NavBar :user="user"/>

    <MiddleBarStaticPages title="Kontakt"/>

    <div class="container pt-4">

      <div class="p-3">

        <p>
          AGAI Sp. z o.o.<br/>
          ul. Złota 7/28<br/>
          00-019 Warszawa<br/><br/>

          E-mail: <A href="mailto:info@edunso.pl">info@edunso.pl</A>

          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
        </p>

      </div>

    </div>

    <Footer/>
  </div>
</template>

<script>
import MiddleBarStaticPages from '../../../elements/MiddleBarStaticPages';
import Footer from '../../../elements/Footer';
import NavBar from "@/elements/Navbar/index.vue";

export default {
  name: 'MetodyPlatnosci',
  components: {
    NavBar,
    MiddleBarStaticPages,
    Footer
  },
  data() {
    return {
      user: {
        id: null,
        permissions: []
      }
    }
  },

  created() {

    this.axios.get(window.API_URL + "user", { withCredentials: true }).then((response) => {

      let data = response.data

      if (data.userId) {

        this.user.id = data.userId
        this.user.permissions = data.permissions

      }

    });

    window.scrollTo({ top: 0, left: 0 });

  },
}
</script>