<template>
  <div class="bg-white">

    <NavBar :user="user"/>

    <MiddleBarStaticPages title="Polityka prywatności"/>

    <div class="container pt-4">

      <div class="p-3">

        <B>I. Szczegółowe informacje dotyczące przetwarzania danych użytkowników korzystających ze strony edunso.pl (klauzula informacyjna)</B>
        <br/>
        <br/>
        1. Administrator Twoich danych osobowych: AGAI Spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie (00-019 Warszawa, ul. Złota 7/28) zarejestrowaną w rejestrze przedsiębiorców prowadzonym przez Sąd Rejonowy dla Krakowa - Śródmieścia Wydział XI Krajowego Rejestru Sądowego pod nr KRS: 0000847307, NIP 6762582015, REGON 386356611, prowadząca serwis pod adresem: edunso.pl.
        <br/>
        <br/>
        2. Z administratorem danych można kontaktować się za pośrednictwem adresu email: info@edunso.pl, lub pisemnie na następujący adres: AGAI Sp. z o.o. 00-019 Warszawa, ul. Złota 7/28.
        <br/>
        <br/>
        <B>W jakich celach są przetwarzane Twoje dane osobowe?</B>
        <br/>
        <br/>
        3. Za Twoją dobrowolną zgodą dane mogą być przetwarzane do celów analitycznych (pliki cookies). Ponadto Twoje dane będą przetwarzane do celu realizacji usług strony edunso.pl. Co więcej, Twoje dane mogą być także przetwarzane w celu dopasowania treści witryn internetowych edunso.pl do Twoich zainteresowań, a także wykrywania botów i nadużyć oraz pomiarów statystycznych i udoskonalenia usług na stronie edunso.pl.
        <br/>
        <br/>
        3.1. W związku z korzystaniem z naszych usług, możemy przetwarzać dane osobowe w celu profilowania. Profilowanie to proces analizy danych osobowych w celu oceny lub przewidzenia aspektów dotyczących osoby fizycznej, w szczególności do analizy lub przewidzenia preferencji, zainteresowań, zachowań i predyspozycji tej osoby.
        <br/>
        <br/>
        3.2. Celem profilowania jest zapewnienie lepszego dostosowania naszych usług do indywidualnych potrzeb i zainteresowań użytkowników oraz doskonalenie naszych produktów i usług.
        <br/>
        <br/>
        3.3. Profilowanie odbywa się na podstawie zgody udzielanej przez użytkownika.
        <br/>
        <br/>
        3.4. Efektem profilowania może być dostarczanie użytkownikowi spersonalizowanych treści, produktów, usług, ofert i reklam, które odpowiadają jego preferencjom i potrzebom. Dzięki profilowaniu możemy również poprawić jakość naszych usług i zwiększyć ich użyteczność.
        <br/>
        <br/>
        3.5. Masz prawo do wyrażenia zgody na profilowanie lub jej odmowy. Możesz również w dowolnym momencie wycofać zgodę na profilowanie. Wycofanie zgody nie wpływa na legalność przetwarzania danych osobowych, które miało miejsce przed jej wycofaniem.
        <br/>
        <br/>
        3.6. Jeśli masz pytania lub wątpliwości dotyczące profilowania danych osobowych lub jego efektów, prosimy o kontakt z nami za pomocą danych podanych w sekcji "Kontakt" niniejszej strony.
        <br/>
        <br/>
        <B>Przez jaki okres będą przechowywane Twoje dane osobowe?</B>
        <br/>
        <br/>
        4. Dane będą przetwarzane w celach analitycznych do momentu wycofania przez Ciebie zgody na takie przetwarzanie. W odniesieniu do usług strony internetowej edunso.pl Twoje dane będą przetwarzane przez okres, w którym usługi te będą świadczone oraz, czasami, po zakończeniu ich świadczenia, jednak wyłącznie jeżeli jest dozwolone lub wymagane w świetle obowiązującego prawa np. przetwarzanie w celach statystycznych, rozliczeniowych lub w celu dochodzenia roszczeń. W takim przypadku dane będą przetwarzane jedynie przez okres niezbędny do realizacji tego typu celów.
        <br/>
        <br/>
        <B>Jaka jest podstawa prawna przetwarzania Twoich danych osobowych?</B>
        <br/>
        <br/>
        5. Podstawą prawną przetwarzania Twoich danych osobowych jest Twoja dobrowolna zgoda na przetwarzanie danych, w tym na cele analityczne (art. 6 ust. 1 lit. a) RODO). W odniesieniu do usług edunso.pl, Twoje dane będą przetwarzane na podstawie art. 6 ust. 1 lit b) RODO (jest to niezbędne do wykonania umowy, której stroną jest osoba, której dane dotyczą). Jeżeli chodzi o wykrywanie botów i nadużyć oraz pomiary statystyczne i udoskonalenie usługi, Twoje dane będą przetwarzane na podstawie art. 6 ust. 1 lit. f) RODO (uzasadniony interes administratora). Uzasadnionym interesem administratora jest dopasowanie treści usług zlokalizowanych na stronach edunso.pl do Twoich potrzeb, zapewnienie bezpieczeństwa tych usług, a także ich ciągłe udoskonalanie.
        <br/>
        <br/>
        6. Wymóg podania danych Podanie danych w celu realizacji usług witryny edunso.pl jest niezbędne do świadczenia tych usług. W razie niepodania tych danych usługa nie będzie mogła być świadczona. Przetwarzanie danych w pozostałych celach wykrywania botów i nadużyć w usługach edunso.pl, pomiarów statystycznych, analitycznych i udoskonalenia usług edunso.pl jest niezbędne w celu zapewnienia wysokiej jakości usług edunso.pl. Niezebranie Twoich danych osobowych w tych celach może uniemożliwić poprawne świadczenie usług.
        <br/>
        <br/>
        <B>Do jakich odbiorców przekazywane będą Twoje dane osobowe?</B>
        <br/>
        <br/>
        7. Twoje dane mogą być przekazywane podmiotom przetwarzającym dane osobowe na zlecenie administratora m.in. dostawcom usług IT – przy czym takie podmioty przetwarzają dane na podstawie umowy z administratorem i wyłącznie zgodnie z poleceniami administratora. Twoje dane mogą być również przygotowane podmiotom uprawnionym do ich uzyskania na podstawie obowiązującego prawa np. organom ścigania w razie zgłoszenia przez organ żądania na odpowiedniej podstawie prawnej (np. dla potrzeb toczącego się postępowania karnego).
        <br/>
        <br/>
        8. Przekazywanie danych poza EOG Twoje dane osobowe nie będą przekazywane do odbiorców znajdujących się w państwach poza Europejskim Obszarem Gospodarczym.
        <br/>
        <br/>
        <B>Jakie są Twoje prawa związane z przetwarzaniem danych osobowych?</B>
        <br/>
        <br/>
        9. Przysługują Ci następujące prawa w związku z przetwarzaniem przez Nas Twoich danych osobowych: – prawo dostępu do Twoich danych, w tym uzyskania kopii danych, – prawo żądania sprostowania danych – prawo do usunięcia danych (w określonych sytuacjach), – prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych, – prawo do ograniczenia przetwarzania danych.
        <br/>
        <br/>
        10. Jeżeli Twoje dane są przetwarzane na podstawie zgody lub w ramach świadczonej usługi (dane są niezbędne w celu świadczenia usługi) możesz dodatkowo skorzystać z poniższych praw: – prawo do wycofania zgody w zakresie w jakim są przetwarzane na tej podstawie. Wycofanie zgody nie ma wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem. – prawo do przenoszenia danych osobowych, tj. do otrzymania od administratora Twoich danych osobowych, w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu maszynowego. Możesz przesłać te dane innemu administratorowi danych.
        <br/>
        <br/>
        11. Jeżeli Twoje dane przetwarzane są w oparciu o uzasadniony interes administratora danych, przysługuje Ci prawo wniesienia sprzeciwu wobec takiego przetwarzania.
        <br/>
        <br/>
        12. W celu skorzystania z powyższych praw należy skontaktować się z administratorem danych. Dane kontaktowe wskazane są wyżej.
        <br/>
        <br/>
        <B>II. Ogólne informacje dotyczące przetwarzania danych Użytkowników przez AGAI sp. z o.o.</B>
        <br/>
        <br/>
        1. Administratorem danych jest AGAI sp. z o.o. (Administrator).
        <br/>
        <br/>
        2. Z Administratorem można się kontaktować we wszystkich sprawach związanych z przetwarzaniem danych osobowych pod następującym adresem e-mail: info@edunso.pl lub korespondencyjnie na adres podany powyżej.
        <br/>
        <br/>
        3. Dane osobowe mogą być zbierane przez Administratora w następujących celach: a. świadczenia usług/ wykonania umowy przez Administratora np.: zgodnie z Regulaminem świadczenia usług lub strony edunso.pl – art. 6 ust. 1 lit. b) RODO; b. przesyłania zamówionych informacji handlowych, w tym newslettera na podstawie zgody – art. 6 ust. 1 lit. a) RODO; c. zapewnienia kontaktu z Administratorem za pośrednictwem formularza kontaktowego – art. 6 ust. 1 lit. f) RODO; d. księgowo-podatkowych– art. 6 ust. 1 lit. c) RODO; e. dochodzenia roszczeń w zw. z zawartymi umowami z Użytkownikami – art. 6 ust. 1 lit. f) RODO; f. wykrywania botów i nadużyć w usługach, pomiarów statystycznych, analitycznych i udoskonalenia usług – art. 6 ust. 1 lit. f) RODO.
        <br/>
        <br/>
        4. Administrator zbiera dane osobowe wyłącznie w zakresie niezbędnym dla realizacji celów, do których są zbierane.
        <br/>
        <br/>
        5. Administrator przetwarza dane osobowe wyłącznie przez czas niezbędny dla realizacji zamierzonych celów.
        <br/>
        <br/>
        6. Użytkownikom przysługują następujące prawa, z których mogą skorzystać (w tym celu należy się skontaktować z Administratorem – dane kontaktowe powyżej): a. Prawo dostępu do danych; b. Prawo do sprostowania danych; c. Prawo do usunięcia danych; d. Prawo do ograniczenia przetwarzania; e. Prawo do przenoszenia danych (o ile do przetwarzania dochodzi na podstawie zgody lub w celu zawarcia umowy); f. Prawo sprzeciwu (o ile dane przetwarzane są na podstawie uzasadnionego interesu administratora); g. Prawo do cofnięcia zgody, o ile przetwarzanie następuje na podstawie zgody; h. Prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych.
        <br/>
        <br/>
        7. Dane mogą być przekazywane podmiotom przetwarzającym dane osobowe na zlecenie Administratora m.in. dostawcom usług IT – przy czym takie podmioty przetwarzają dane na podstawie umowy z Administratorem i wyłącznie zgodnie z poleceniami Administratora. Dane mogą być również przekazane podmiotom uprawnionym do ich uzyskania na podstawie obowiązującego prawa np. organom ścigania w razie zgłoszenia przez organ żądania na odpowiedniej podstawie prawnej (np. dla potrzeb toczącego się postępowania karnego).
        <br/>
        <br/>
        8. Dane osobowe nie będą przekazywane do odbiorców znajdujących się w państwach poza Europejskim Obszarem Gospodarczym.
        <br/>
        <br/>
        9. Administrator dokłada wszelkich starań, aby zapewnić wszelkie środki fizycznej, technicznej i organizacyjnej ochrony danych osobowych przed ich przypadkowym czy umyślnym zniszczeniem, przypadkową utratą, zmianą, nieuprawnionym ujawnieniem, wykorzystaniem czy dostępem, zgodnie ze wszystkimi obowiązującymi przepisami.
        <br/>
        <br/>
        10. Dane jakie przetwarzamy: Imię i nazwisko, adres e-mail, telefon, adres firmy. Pełne informacje o przetwarzaniu danych osobowych znajdują się w klauzulach informacyjnych przekazywanych Użytkownikom w momencie zbierania od nich danych osobowych dla określonych celów.
        <br/>
        <br/>
        11. Sklep internetowy przetwarza Pani/Pana dane osobowe w następujących celach:<br/>
        a) Za Twoją dobrowolną zgodą dane mogą być przetwarzane do celów analitycznych (pliki cookies).
        Ponadto Twoje dane będą przetwarzane do celu realizacji usług strony edunso.pl.
        Twoje dane mogą być także przetwarzane w celu dopasowania treści witryn internetowych edunso.pl
        do Twoich zainteresowań, a także wykrywania botów i nadużyć oraz pomiarów statystycznych i
        udoskonalenia usług na stronie edunso.pl.
        <br/>
        b) przekazania Pani/Pana danych osobowych do ING Banku Śląskiego S.A. („Bank”) w związku z: <br/>
        a. świadczeniem przez Bank na rzecz Sklepu internetowego usługi udostępnienia infrastruktury do
        obsługi płatności przez Internet (podstawa prawna: art. 6 ust. 1 lit. f) Rozporządzenia). <br/>
        b. obsługą i rozliczaniem przez Bank płatności dokonywanych przez klientów Sklepu internetowego
        przez Internet przy użyciu instrumentów płatniczych (podstawa prawna: art. 6 ust. 1 lit.
        f) Rozporządzenia). <br/>
        c. w celu weryfikacji przez Bank należytego wykonania umów zawartych ze Sklepem internetowym,
        w szczególności zapewnienia ochrony interesów płatników w związku ze składanymi przez
        nich reklamacjami (podstawa prawna: art. 6 ust. 1 lit. f) Rozporządzenia). <br/>
        c) przekazania Pani/Pana danych osobowych do Twisto Polska sp. z o.o. w związku z możliwością
        zaproponowania dokonania zapłaty za nabyty towar lub usługę przez Twisto Polska sp. z o.o. w
        ramach umowy zlecenia obejmującej formułę zakupową „Kup z Twisto” i udostępnieniem tej
        formuły zakupowej przez Sklep internetowy, a także celu weryfikacji przez Twisto Polska Sp. z o.o.
        należytego wykonania takich umów zlecenie (podstawa prawna: art. 6 ust. 1 lit. f) Rozporządzenia).
        <br/>
        <br/>
        12. W związku z przetwarzaniem danych osobowych w celach określonych w ust. 3 i 4, Pani/Pana dane osobowe mogą zostać udostępnione przez Sklep internetowy innym odbiorcom lub kategoriom odbiorców danych osobowych, którymi mogą być:
        <br/>
        a) ING Bank Śląski S.A. <br/>
        b) Twisto Polska sp. z o.o.
        <br/>
        <br/>
        13. W przypadku, gdy podanie przez Panią/Pana danych osobowych następuje w celu zawarcia umowy ze Sklepem internetowym, podanie przez Panią/Pana danych osobowych jest warunkiem zawarcia tej Umowy. Podanie danych osobowych w tej sytuacji jest dobrowolne, jednak konsekwencją niepodania tych danych będzie brak możliwości zawarcia umowy ze Sklepem internetowym.
        <br/>
        <br/>
        14. W przypadku, gdy podanie przez Panią/Pana danych osobowych następuje w celu przekazania Pani/Pana danych osobowych do Twisto Polska sp. z o.o. przed zawarciem umowy sprzedaży towaru (lub usługi) nabywanego w Sklepie internetowym, przekazanie tych danych jest warunkiem zawarcia umowy sprzedaży w związku modelem biznesowym prowadzenia działalności przyjętym przez Sklep internetowy.
        <br/>
        <br/>
        15. W przypadku przekazania Pani/Pana danych osobowych do Banku w związku z obsługą i rozliczaniem płatności dokonywanych przez Panią/Pana płatności na rzecz Sklepu internetowego przez Internet przy użyciu instrumentów płatniczych, podanie danych jest wymagane w celu realizacji płatności i przekazania potwierdzenia jej dokonania przez Bank na rzecz Sklepu internetowego.
        <br/>
        <br/>
        16. W przypadku przekazania Pani/Pana danych osobowych do Banku w celu weryfikacji przez Bank należytego wykonania umów zawartych ze Sklepem internetowym, w szczególności zapewnienia ochrony interesów płatników w związku ze składanymi przez nich reklamacjami podanie tych danych jest wymagane w celu umożliwienia realizacji umowy zawartej pomiędzy Sklepem internetowym a Bankiem.
        <br/>
        <br/>
        17. W przypadku przekazania Pani/Pana danych osobowych do Twisto Polska sp. z o.o. w związku z możliwością zaproponowania Pani/Panu dokonania zapłaty ceny za nabyty przez Panią/Pana towar lub usługę przez Twisto Polska sp. z o.o. w ramach umowy zlecenia obejmującej formułę zakupową „Kup z Twisto” i udostępnieniem tej formuły przez Sklep internetowy, podanie tych danych i przetwarzanie ich w tym celu jest wymagane w związku modelem biznesowym prowadzenia działalności przyjętym przez Sklep internetowy i w celu realizacji umowy zawartej pomiędzy Sklepem internetowym a Twisto Polska Sp. z o.o.
        <br/>
        <br/>
        <b>III. Postanowienia końcowe</b>
        <br/>
        <br/>
        1. Korzystając ze strony internetowej edunso.pl jak i z funkcji Serwisu, wyrażasz zgodę na postanowienia zawarte w niniejszej Polityce Prywatności, która jest nieodłącznym elementem Regulaminu.
        <br/>
        <br/>
        2. Usługodawca zastrzega sobie prawo do wprowadzania zmian w niniejszej Polityce Prywatności, z tym zastrzeżeniem, że zmiany te nie wpłyną na to, że edunso.pl nigdy nie udostępni danych Użytkowników osobom trzecim.
        <br/>
        <br/>
        3. W sprawach nieuregulowanych regulaminem zastosowanie mają postanowienia Kodeksu cywilnego i odpowiednich ustaw prawa polskiego, a także prawa Unii Europejskiej, w szczególności RODO (Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE).


      </div>

    </div>

    <Footer/>
  </div>
</template>

<script>
import MiddleBarStaticPages from '../../../elements/MiddleBarStaticPages';
import Footer from '../../../elements/Footer';
import NavBar from "@/elements/Navbar/index.vue";

export default {
  name: 'PolitykaPrywatnosci',
  components: {
    NavBar,
    MiddleBarStaticPages,
    Footer
  },
  data() {
    return {
      user: {
        id: null,
        permissions: []
      }
    }
  },

  created() {

    this.axios.get(window.API_URL + "user", { withCredentials: true }).then((response) => {

      let data = response.data

      if (data.userId) {

        this.user.id = data.userId
        this.user.permissions = data.permissions

      }

    });

    window.scrollTo({ top: 0, left: 0 });

  },
}
</script>

 