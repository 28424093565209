<template>
  <div class="bg-white">

    <NavBar :user="user"/>

    <MiddleBarStaticPages title="Polityka cookies"/>

    <div class="container pt-4">

      <div class="p-3">

        1.	Administrator wykorzystuje pliki cookies (ciasteczka), czyli niewielkie informacje tekstowe, przechowywane na urządzeniu końcowym Użytkownika (np. komputerze, tablecie, smartfonie). Cookies mogą być odczytywane przez system teleinformatyczny Administratora.
        <br/>
        <br/>
        2.	Administrator przechowuje pliki cookies na urządzeniu końcowym Użytkownika, a następnie uzyskuje dostęp do informacji w nich zawartych w celach:
        <br/>
        a) tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy korzystają ze stron internetowych, co umożliwia ulepszanie ich struktury i zawartości;
        <br/>
        b) marketingowych (remarketing)
        <br/>
        c) określania profilu Użytkownika w celu wyświetlania mu dopasowanych materiałów w sieciach reklamowych, w szczególności sieci Google,
        <br/>
        d) zapewnienia prawidłowego działania Sklepu.
        <br/>
        <br/>
        3.	Istnieje możliwość takiej konfiguracji przeglądarki internetowej przez Użytkownika, która uniemożliwia przechowywanie plików cookies na urządzeniu końcowym. Jednak, w takiej sytuacji, korzystanie ze strony internetowej przez Użytkownika może być utrudnione.
        <br/>
        <br/>
        4.	Pliki cookies mogą być przez Użytkownika usunięte po ich zapisaniu poprzez odpowiednie funkcje przeglądarki internetowej, programy służące w tym celu lub skorzystanie z odpowiednich narzędzi dostępnych w ramach systemu operacyjnego, z którego korzysta Użytkownik.
        <br/>
        <br/>
        5.	Pod tymi linkami zamieszczone są informacje o sposobach usunięcia cookies w najpopularniejszych przeglądarkach internetowych:
        <br/>•	Firefox: http://support.mozilla.org/pl/kb/usuwanie-ciasteczek,
        <br/>•	Opera: http://help.opera.com/Linux/9.60/pl/cookies.html,
        <br/>•	Internet Explorer: http://support.microsoft.com/kb/278835/pl,
        <br/>•	Chrome: http://support.google.com/chrome/bin/answer.py?hl=pl&answer=95647
        <br/>•	Safari: http://support.apple.com/kb/HT1677?viewlocale=pl_PL


      </div>

    </div>

    <Footer/>
  </div>
</template>

<script>
import MiddleBarStaticPages from '../../../elements/MiddleBarStaticPages';
import Footer from '../../../elements/Footer';
import NavBar from "@/elements/Navbar/index.vue";

export default {
  name: 'PolitykaPrywatnosci',
  components: {
    NavBar,
    MiddleBarStaticPages,
    Footer
  },
  data() {
    return {
      user: {
        id: null,
        permissions: []
      }
    }
  },

  created() {

    this.axios.get(window.API_URL + "user", { withCredentials: true }).then((response) => {

      let data = response.data

      if (data.userId) {

        this.user.id = data.userId
        this.user.permissions = data.permissions

      }

    });

    window.scrollTo({ top: 0, left: 0 });

  },
}
</script>

 