import Course from './pages/Course/index';

import PanelApps from './pages/Panel/Apps/index';
import PanelCourses from './pages/Panel/Courses/index';
import PanelCourse from './pages/Panel/Course/index';
import PanelApp from './pages/Panel/App/index';

import AccessDenied from './pages/Static/AccessDenied/index';
import Regulamin from './pages/Static/Regulamin/index';
import PolitykaPrywatnosci from './pages/Static/PolitykaPrywatnosci/index';
import MetodyPlatnosci from './pages/Static/MetodyPlatnosci/index';
import DanePodmiotu from './pages/Static/DanePodmiotu/index';
import Kontakt from './pages/Static/Kontakt/index';
import Cookies from './pages/Static/Cookies/index';

export default [

    //{ path: '/', component: Course },
    { path: '/', redirect: '/pakiet/1-mln-ai'},

    { path: '/kurs/:slug', component: Course },
    { path: '/pakiet/:slug', component: Course },
    { path: '/access-denied', component: AccessDenied },

    { path: '/panel', component: PanelApps},

    { path: '/panel/kursy', component: PanelCourses},

    { path: '/panel/kurs', component: PanelCourse },
    { path: '/panel/kurs/:slug', component: PanelCourse },
    { path: '/panel/kurs/:slug/:lesson', component: PanelCourse },

    { path: '/panel/app/:slug', component: PanelApp },
    { path: '/panel/app/:slug/:other', component: PanelApp },

    // Static pages
    { path: '/regulamin', component: Regulamin },
    { path: '/regulamin-kursow', component: Regulamin },
    { path: '/polityka-prywatnosci', component: PolitykaPrywatnosci },
    { path: '/metody-platnosci', component: MetodyPlatnosci },
    { path: '/cookies', component: Cookies },
    { path: '/dane-podmiotu', component: DanePodmiotu },
    { path: '/kontakt', component: Kontakt }

]