<template>

 <div class="middlebar">

    <div class="pl-4 text-white pt-3 pb-5">

      <h2 class="mt-4 text-white">{{ title }}</h2>

    </div>

 </div>

</template>

<script>
export default {
  name: 'MiddleBar',
  props: [
    'title',
  ],
  data() {
    return {

    }
  }
}
</script>

 