<template>

 <div class="middlebar">

    <div class="pl-4 text-white pt-3 pb-5">

      <div class="mb-5 small text-muted breadcumber">
        <b-link :to="'/'">Home</b-link>  <span class="material-symbols-outlined align-middle fs-20 fw-300">arrow_right</span>
        <span v-if="(type === 'course')"><b-link :to="'#'">Kursy</b-link> <span class="material-symbols-outlined align-middle fs-20 fw-300">arrow_right</span></span>
        <span v-if="(type === 'package')"><b-link :to="'/pakiety'" >Pakiety</b-link></span>
      </div>

      <span v-for="item in tags" v-bind:key="item">
        <span class="badge text-bg-primary mr-1 tag"><A href="#">{{ item }}</A></span>
      </span>

      <h1 class="mt-4">{{ name }}</h1>

      <div class="text-muted">
        <span v-html="slogan"></span>
      </div>

      <div class="text-muted mt-4 meta">
        <span v-if="listeners > 0"><span class="material-symbols-outlined align-middle fs-20 fw-300 icon">group</span> {{ listeners }} Uczestników</span>
        <span v-if="lessons.length > 0"><span class="material-symbols-outlined align-middle fs-20 fw-300 ml-3 icon">description</span> {{ lessons.length }} lekcji</span>
      </div>

      <!-- <div class="text-muted mt-2 meta small author">
       Autor: <A href="#autor">{{ author.name }}</A>
      </div> -->

    </div>

 </div>

</template>

<script>
export default {
  name: 'MiddleBar',
  props: [
    'type',
    'name',
    'slogan',
    'tags',
    'category',
    'lessons',
    'author',
    'listeners'
  ],
  data() {
    return {

    }
  }
}
</script>

 