<template>
  <div class="bg-white">

    <NavBar :user="user"/>

    <MiddleBarStaticPages title="Dane Podmiotu"/>

    <div class="container pt-4">

      <div class="p-3">

        <p>
          <B>Podmiot</B><br/>

          AGAI Spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie (00-019 Warszawa, ul. Złota 7/28) zarejestrowaną w rejestrze przedsiębiorców prowadzonym przez Sąd Rejonowy dla Krakowa - Śródmieścia Wydział XI Krajowego Rejestru Sądowego pod nr KRS: 0000847307, NIP 6762582015, REGON 386356611

          <br/><br/><B>Kontakt</B><br/>

          info@edunso.pl

          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
        </p>

      </div>

    </div>

    <Footer/>
  </div>
</template>

<script>
import MiddleBarStaticPages from '../../../elements/MiddleBarStaticPages';
import Footer from '../../../elements/Footer';
import NavBar from "@/elements/Navbar/index.vue";

export default {
  name: 'MetodyPlatnosci',
  components: {
    NavBar,
    MiddleBarStaticPages,
    Footer
  },
  data() {
    return {
      user: {
        id: null,
        permissions: []
      }
    }
  },

  created() {

    this.axios.get(window.API_URL + "user", { withCredentials: true }).then((response) => {

      let data = response.data

      if (data.userId) {

        this.user.id = data.userId
        this.user.permissions = data.permissions

      }

    });

    window.scrollTo({ top: 0, left: 0 });

  },
}
</script>

 