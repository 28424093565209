<template>

 <div class="CourseRightBar">

     <b-row class="priceBox">
       <b-col cols="6" class="pt-2 pb-2">
         <div class="pl-3 pr-3">

           <div class="mainPrice">
             <span class="actualPrice">{{ price }}zł</span>
             <small class="oldPrice" v-if="oldPrice"> <s>{{ oldPrice }}zł</s></small>
           </div>
           <small v-if="oldPrice">Najniższa cena z 30 dni</small>

         </div>
       </b-col>
       <b-col cols="6">
         <b-button size="lg" variant="primary" class="mt-4" block :href="buyLink">Kup
           <span v-if="(type === 'course')">kurs</span>
           <span v-if="(type === 'package')">pakiet</span>

           <span class="material-symbols-outlined align-middle fw-300">
            chevron_right
           </span>

         </b-button>
       </b-col>
     </b-row>

     <b-alert show variant="primary" class="small" v-if="timeOfferTo">
       ⏱️ Ten            <span v-if="(type === 'course')">kurs</span>
       <span v-if="(type === 'package')">pakiet</span> kupisz w promocyjnej cenie jeszcze do {{ timeOfferTo }}. <A :href="buyLink">Kup teraz ></A>
     </b-alert>

     <div class="p-3" style="font-size: 0.9em;border: 1px solid #ddd; background-color: #fff;border-radius: 5px;">
       ❗<B style="color:#000;">Raty 0%</B>
        Kup <span v-if="(type === 'course')">kurs</span> <span v-if="(type === 'package')">pakiet</span>
       wybierając opcję rat 0%. <a :href="buyLink">👉🏼 Biorę na raty</a>
     </div>

     <div class="mt-4 courseContainBox">
       <h5>Co otrzymasz dodatkowo?</h5>

       <b-row>
         <b-col cols="6">
           <span v-if="isNoLimitAccess">
             <span class="material-symbols-outlined align-middle text-muted fw-300 icon">all_inclusive</span> Nielimitowany dostęp <br/>
           </span>
           <span class="material-symbols-outlined align-middle text-muted fw-300 icon">phone_iphone</span> Dostęp mobilny<br/>
           <span class="material-symbols-outlined align-middle text-muted fw-300 icon">support_agent</span> Wsparcie autora<br/>
         </b-col>
         <b-col cols="6">
           <span class="material-symbols-outlined align-middle text-muted fw-300 icon">quiz</span> Testy i zadania<br/>
           <span class="material-symbols-outlined align-middle text-muted fw-300 icon">verified_user</span> Certyfikat ukończenia<br/>
         </b-col>
       </b-row>

     </div>

     <div class="mt-4" v-if="countShowedToday">
       <b-alert show variant="primary">🔥
         <span v-if="(type === 'course')">Kurs</span>
         <span v-if="(type === 'package')">Pakiet</span>
         obejrzało dziś już {{ countShowedToday }} osoby.</b-alert>
     </div>

     <div class="mt-4 learningFeatures" v-if="learningFeatures">

       <h5>Czego się nauczysz?</h5>

       <div class="learningFeaturesBox mt-3">

         <div v-for="item in learningFeatures" v-bind:key="item">

           <span class="material-symbols-outlined align-middle fw-600 icon">check</span>

           <span v-html="item"></span>

           <br/><br/>

         </div>

       </div>

     </div>

 </div>

</template>

<script>
export default {
  name: 'CourseRightBar',
  props: [
      'price',
      'oldPrice',
      'buyLink',
      'timeOfferTo',
      'countOfHours',
      'learningFeatures',
      'lessons',
      'isNoLimitAccess',
      'type'
  ],
  data() {
    return {
      "countShowedToday": 165,
    }
  }
}
</script>

 