<template>

  <div class="pt-2">

    <div>

      <b-row align-v="center">

        <b-col lg="12">

          <div class="mt-4">

            <div class="mt-3">

              <label>Twoje imię i nazwisko:</label>

              <b-input-group>

                <b-form-input v-model="name" size="lg" ref="name" placeholder=""></b-form-input>

              </b-input-group>

            </div>

            <div class="mt-3">

              <label>Twój e-mail:</label>

              <b-input-group>

                <b-form-input v-model="email" size="lg" ref="email" placeholder=""></b-form-input>

              </b-input-group>

            </div>

            <div class="mt-3">

              <label>Numer telefonu:</label>

              <b-input-group>

                <b-form-input v-model="phone" size="lg" ref="phone" placeholder=""></b-form-input>

              </b-input-group>

            </div>


            <div class="mt-3">

              <label>Podaj numer paczkomatu (<A href="https://inpost.pl/znajdz-paczkomat" target="_blank">https://inpost.pl/znajdz-paczkomat</A>):</label>

              <b-input-group>

                <b-form-input v-model="paczkomat" size="lg" ref="phone" placeholder="np. WAW67H"></b-form-input>

              </b-input-group>

            </div>


          </div>

        </b-col>

      </b-row>

      <b-row align-v="center" class="mt-2" style="min-height: 40px;">

        <b-col lg="6">

        </b-col>

        <b-col lg="6">

          <b-button variant="outline-success mr-1" ref="save" @click="save()" class="mt-3 float-right">
          <span class="material-symbols-outlined align-middle">
            bolt
          </span>
            Zapisz
          </b-button>

        </b-col>

      </b-row>

      <hr/>

    </div>

  </div>

</template>

<script>

export default {
  name: 'AppOdbierzStatuetke',
  components: {
  },
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      paczkomat: "",
    }
  },
  methods: {


    save: function() {

      if(!this.name) return false;
      if(!this.email) return false;
      if(!this.phone) return false;
      if(!this.paczkomat) return false;

      this.$refs.save.disabled = true

      this.axios.post(window.API_URL + "save/statuetka/", { 'name': this.name, 'email': this.email, 'phone': this.phone, 'paczkomat': this.paczkomat }, { withCredentials: true, timeout: 3000000 }).then((response) => {

        let data = response.data

        if(data.redirectTo) {

          top.location.href = data.redirectTo;

        }

        if(data._id) {

          top.location.href = "https://szkolenia-ai.online/paczka-zostanie-wyslana-cert/"

        } else {

          alert("Błąd. Sprawdź poprawność podanych danych.")

          this.$refs.save.disabled = false

        }

      }).catch(function() {

        this.$refs.generatePost.disabled = false

      })


    },



  },
  computed: {

  },
  created() {



  }

}
</script>

<style>

.pointer { cursor: pointer; }
.bold { font-weight: 600 !important; }
code { color: #4e82f7 !important; }
</style>
