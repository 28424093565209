<template>
  <div id="app">

    <router-view></router-view>

  </div>
</template>

<script>



export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
