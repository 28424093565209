<template>

  <div class="Panel bg-white">

    <NavBar
        :user="user"
    />

    <div class="">

      <b-row>

        <b-col cols="12" order="6" order-lg="1" lg="3" class="p-3" style="border-right: 1px solid #e9e9e9;background-color: #f9f9f9;">

          <h5 class="text-dark">
            <b-button variant="light" size="sm" class="d-none"> x </b-button>
            {{ course.name }}
          </h5>

          <hr/>

          <b-list-group class="mt-2">

            <b-list-group-item v-for="module in course.modules" :key="module.id" style="border: 0px;background-color: transparent;">

              <B style="color: #0d51dc;font-weight: 600;"><span v-if="!module.dontShowNumber">{{ module.id }}.</span> {{ module.name }}</B><br/>

              <span v-for="vlesson,i in (course.lessons.filter(item => item.module === module.id))" :key="vlesson.id">
                <router-link :to="'/panel/kurs/' + course.slug + '/' + vlesson.id" class="ml-2" :class="{ 'active-lesson': lesson.id === vlesson.id }" style="color:#000;">
                  <span v-if="!module.dontShowNumber">{{ module.id }}.</span>
                  {{ (i+1) }}.
                  <span v-if="vlesson.name"> {{ vlesson.name }} </span>
                  <span v-if="vlesson.title"> {{ vlesson.title }}</span>
                </router-link><br/>
              </span>

            </b-list-group-item>

          </b-list-group>

        </b-col>

        <b-col cols="12" order="1" order-lg="6" lg="9" class="p-3 pt-4">

          <h2 class="text-dark">
            <b-button variant="light" size="sm" class="d-none"> x </b-button>
            <span v-if="lesson.name"> {{ lesson.name }} </span>
            <span v-if="lesson.title"> {{ lesson.title }}</span>
            <b-button variant="light" class="float-right d-none" size="sm"> x </b-button>

          </h2>

          <hr/>

          <div v-if="lesson.type === 'text'" v-html="content"></div>

          <div v-if="lesson.type === 'video-vimeo' || lesson.contentType === 'video-vimeo'" :class="{ 'embed-responsive': !vimeoOptions.responsive, 'embed-responsive-1by1': !vimeoOptions.responsive }">
            <vimeo-player ref="player" :video-id="(lesson.videoId ? lesson.videoId.toString() : lesson.contentData.videoId.toString())"  player-width="100%" :options="vimeoOptions" class="vimeo" width="100%;"/>
          </div>

        </b-col>

      </b-row>

    </div>

    <Footer/>

  </div>

</template>

<script>

import Footer from '@/elements/Footer';
import NavBar from "@/elements/Navbar/index.vue";

export default {

  name: 'Panel',

  components: {
    NavBar, Footer
  },

  data() {

    return {
      course: {
        slug: null
      },
      lesson: {
        id: null
      },
      content: "",
      user: {
        id: null,
        permissions: []
      },
      vimeoOptions: {
        responsive: false,
        title: false,
        portrait: false,
        byline: false,
        controls: true,
        sidedock: true
      }
    }

  },

  created() {

    this.axios.get(window.API_URL + "user", { withCredentials: true }).then((response) => {

      let data = response.data

      if(data.redirectTo) {

        top.location.href = data.redirectTo;

      }

      if(data.userId) {

        this.user.id = data.userId
        this.user.permissions = data.permissions

        if(this.$route.params.slug) {

          if(this.$route.params.lesson)
            this.load(this.$route.params.slug, this.$route.params.lesson)
          else
            this.load(this.$route.params.slug, 1);

        }

      }

    });

  },


  methods: {

    load: function(slug, lesson = false) {

      window.scrollTo({ top: 0, left: 0 });

      if(slug) {
        this.course.slug = slug
      }

      if(lesson) {
        this.lesson.id = lesson
      }

      lesson = this.lesson.id
      slug = this.course.slug

      this.axios.get(window.API_URL + "courses/get/" + slug, { withCredentials: true }).then((response) => {

        let data = response.data

        this.course = data

        if(this.course.isVertical)
          this.vimeoOptions.responsive = false
        else
          this.vimeoOptions.responsive = true

        /*if (this.course.isVertical)
          this.vimeoOptions.responsive = false
        else
          this.vimeoOptions.responsive = true

        // Checking permissions
        if (!this.user.permissions.includes(this.course.neededPermissions)) {

          this.$router.push('/access-denied')

        }*/


        if (lesson) {

          this.lesson = this.course.lessons.find(x => x.id === parseInt(lesson))

          // if this.lesson free or user have permissions


          this.axios.get(window.API_URL + "courses/getLessonContent/" + slug + "/" + lesson, { withCredentials: true }).then((response) => {

            this.content = response.data.content

          });

        }

      });


    }

  },

  watch: {

    '$route.params.slug' (slug) {

      this.load(slug, this.lesson.id)

    },

    '$route.params.lesson' (lesson) {

      if(lesson == undefined) {
        this.$router.go('/panel/kurs/' + this.course.slug + '/1')
      }
      else
        this.load(this.course.slug, lesson)

    },

  },
}

</script>

<style>
.vimeo {
  left: 0;
  top: 0;
  width: 100%;
}
</style>