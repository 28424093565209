<template>
 <div>
   <div class="bg-blue-dark Footer">

     <b-row class="container">

       <b-col cols="12" lg="3" class="pt-3 pb-3">
       </b-col>

       <b-col cols="12" lg="3" class="pt-3 pb-3 logo">
         <span class="material-symbols-outlined align-middle icon-logo">cognition</span>
         edunso<small>.pl</small>
       </b-col>

       <b-col cols="12" lg="3" class="pt-3 pb-3">
         <router-link :to="'/polityka-prywatnosci'"><span class="text-muted material-symbols-outlined align-middle fs-20 fw-300 mt--2px">policy</span> Polityka prywatności</router-link><br/>
         <router-link :to="'/cookies'"><span class="text-muted material-symbols-outlined align-middle fs-20 fw-300 mt--2px">policy</span> Polityka cookies</router-link><br/>
         <router-link :to="'/regulamin'"><span class="text-muted material-symbols-outlined align-middle fs-20 fw-300 mt--2px">history_edu</span> Regulamin</router-link><br/>
         <router-link :to="'/metody-platnosci'"><span class="text-muted material-symbols-outlined align-middle fs-20 fw-300 mt--2px">credit_card</span> Metody płatności</router-link><br/>
       </b-col>

       <b-col cols="12" lg="3" class="pt-3 pb-3">
         <router-link :to="'/dane-podmiotu'"><span class="text-muted material-symbols-outlined align-middle fs-20 fw-300 mt--2px">contact_mail</span> Dane podmiotu</router-link><br/>
         <router-link :to="'/kontakt'"><span class="text-muted material-symbols-outlined align-middle fs-20 fw-300 mt--2px">call</span> Kontakt</router-link><br/>
       </b-col>

     </b-row>

    </div>

   <div class="bg-black Footer-black p-3">
     <div class="container">
       Usługodawca – AGAI Spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie (00-019 Warszawa, ul. Złota 7/28) zarejestrowaną w rejestrze przedsiębiorców prowadzonym przez Sąd Rejonowy dla Krakowa - Śródmieścia Wydział XI Krajowego Rejestru Sądowego pod nr KRS: 0000847307, NIP 6762582015, REGON 386356611, o kapitale zakładowym w wysokości 5 000 zł.
     </div>
   </div>

 </div>

</template>

<script>
export default {
  name: 'Footer',
  props: {
     
  }
}
</script>

 