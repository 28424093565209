<template>

  <div class="Panel bg-white">

    <NavBar
        :user="user"
    />

    <div class="">

      <b-row>

        <b-col cols="12" lg="2" class="p-3" style="border-right: 1px solid #e9e9e9;background-color: #f9f9f9;">

          <b-list-group>
            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel'">
              <span class="material-symbols-outlined align-middle fw-600 icon">apps</span>
              Aplikacje
            </b-list-group-item>

            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel/kursy'">
              <span class="material-symbols-outlined align-middle fw-600 icon" style="color:#585bff;">menu_book</span>
              Kursy
            </b-list-group-item>
          </b-list-group>

        </b-col>

        <b-col cols="12" lg="10" class="p-3 pt-4">

          <b-row>

            <b-col lg="3" class="mt-1" order="6" order-sm="1">

              <div class="text-uppercase small mb-2">Przeglądaj</div>

              <div style="cursor:pointer;" v-on:click="selectedTags = []">Wszystkie kursy</div>

              <hr/>

              <div v-for="tag in tags" :key="tag" class="mt-1" style="cursor:pointer;" v-on:click="addTagToFilter(tag)">
              <span class="material-symbols-sharp align-middle" v-if="selectedTags.includes(tag)">
                check_box
              </span>
                <span class="material-symbols-sharp align-middle" v-if="!selectedTags.includes(tag)">
                check_box_outline_blank
              </span>
                {{ tag }}
              </div>

            </b-col>

            <b-col lg="9" order="1" order-sm="6">

              <b-form-input class="mb-2 mt-1" v-model="search" placeholder="Szukaj kursu..."></b-form-input>

              <b-row>

                <b-col class="mt-3" lg="4" sm="6" cols="6" v-for="item in sortedItems()" :key="item.slug">

                  <b-card no-body class="overflow-hidden" :class="{ 'disabled-app': !user.permissions.includes(item.neededPermissions) }">

                    <b-card-body>

                      <a :href="( '/panel/kurs/' + item.slug + '/')"  class="mb-3" v-if="!item.availableFrom">
                        <img :src="require('@/assets/img/courses/' + item.cover)" style="width:100%;"/>
                      </a>

                      <a href="#"  class="mb-3" v-if="item.availableFrom">
                        <img :src="require('@/assets/img/courses/' + item.cover)" style="width:100%;"/>
                      </a>

                      <h6 class="font-weight-bold">

                        <a :href="('/panel/kurs/' + item.slug + '/')" class="text-dark" v-if="!item.availableFrom">
                          <b-badge variant="success" v-if="item.isBestseller">BESTSELLER</b-badge>
                          {{ item.name }}
                        </a>

                        <a href="#" class="text-dark" v-if="item.availableFrom">
                          <b-badge variant="success" v-if="item.isBestseller">BESTSELLER</b-badge>
                          {{ item.name }}
                        </a>

                      </h6>

                      <b-card-text class="small">

                        <div v-if="item.availableFrom">
                          Dostępne od: {{ item.availableFrom }}
                        </div>

                        {{ item.slogan }}

                        <br/>
                        <div style="color: #898989;" class="mt-2" v-if="item.showMetaData">
                          <div v-if="item.countOfHours">
                            <span class="material-symbols-sharp align-middle" style="font-size: 1rem;">schedule</span> &nbsp; Czas trwania: {{ item.countOfHours }}h<br/>
                          </div>
                          <div v-if="item.duration">
                            <span class="material-symbols-sharp align-middle" style="font-size: 1rem;">schedule</span> &nbsp; Czas trwania: {{ item.duration }}h<br/>
                          </div>
                          <span class="material-symbols-sharp align-middle" style="font-size: 1rem;">play_lesson</span> &nbsp;Lekcje: {{ item.lessons.length }}  <br/>
                          <div v-if="item.addons.length > 0">
                            <span class="material-symbols-sharp align-middle" style="font-size: 1rem;">widgets</span> &nbsp;Dodatków: {{ item.addons.length }}
                          </div>
                        </div>
                        <br/>

                        <div v-if="(user.permissions.includes(item.neededPermissions) && !item.availableFrom)" class="text-center text-success">
                          <b-button class="mt-2" variant="light" :to="('/panel/kurs/' + item.slug + '/')" block size="sm">Przejdź do kursu</b-button>
                        </div>

                        <div v-if="!user.permissions.includes(item.neededPermissions)" class="text-center text-danger">
                          <span class="material-symbols-sharp align-middle" style="font-size: 1rem;">info</span> Nie masz dostępu

                          <b-button class="mt-2" variant="success" :href="(item.buyLink)" block size="sm">Uzyskaj dostęp</b-button>
                        </div>

                      </b-card-text>

                    </b-card-body>

                  </b-card>

                </b-col>

              </b-row>

            </b-col>

          </b-row>


        </b-col>

      </b-row>

    </div>

    <Footer/>

  </div>

</template>

<script>

import Footer from '@/elements/Footer';
import NavBar from "@/elements/Navbar/index.vue";

export default {

  name: 'Panel',

  components: {
    NavBar, Footer
  },

  data() {

    return {
      courses: [],
      sortBy: 'order',
      sortDirection: 'asc',
      search: '',
      tags: [
        "AI",
        "Blogowanie",
        "Copywriting",
        "E-booki",
        "E-mail marketing",
        "E-marketing",
        "Facebook",
        "Grafika",
        "Instagram",
        "Lista mailingowa",
        "Nagrania z Mastermind",
        "Podcasty",
        "Produktywność",
        "Programowanie",
        "SEO",
        "Snapchat",
        "YouTube",
        "TikTok",
        "Video"
      ],
      selectedTags: [],
      user: {
        id: null,
        permissions: []
      }
    }

  },

  created() {

    this.axios.get(window.API_URL + "user", { withCredentials: true }).then((response) => {

      let data = response.data

      if(data.redirectTo) {

        top.location.href = data.redirectTo;

      }

      if(data.userId) {

        this.user.id = data.userId
        this.user.permissions = data.permissions


        this.axios.get(window.API_URL + "courses/getAllCourses").then((response) => {

          let data = response.data

          data.data.forEach(element => {

            this.axios.get(window.API_URL + "courses/get/" + element.slug).then((response) => {

              var add = response.data;

              if(!add.isHidden) {

                add.order = element.order;
                add.neededPermissions = element.neededPermissions;

                if(add.showOnlyIfHasAPerms) {

                  if(this.user.permissions.includes(add.neededPermissions)) {

                    this.courses.push(add)

                  }

                } else {

                  this.courses.push(add)

                }


              }


            })

          })

        })


      }

    });

  },

  methods: {

    addTagToFilter: function(tag) {

      if(!this.selectedTags.includes(tag)) {

        this.selectedTags.push(tag)

      } else {

        this.selectedTags = this.selectedTags.filter(item => item !== tag)

      }


    },

    sortedItems: function (){

      var search = this.search.toLowerCase();

      var arr = this.courses;

      // Selected tags
      if(this.selectedTags.length > 0) {

        arr = [];

        this.courses.forEach((element) => {

          var add = false

          element.tags.forEach((tag) => {

            if(this.selectedTags.includes(tag)) {

              add = true

            }

          })

          if(add)
            arr.push(element);

        })

        /*element.tags.forEach((tag) => {

            if(this.selectedTags.includes(tag))
              arr.push(element);
          })*/
        //arr = arr.filter((allNameObject) => this.selectedTags.includes(allNameObject))

      }

      if(search) {

        arr = arr.map(object => {
          object.name = object.name.toLowerCase()
          return object;
        });

        arr = arr.filter(function(el) {
          return el.name.indexOf(search) > -1
        });

      }

      return arr.slice().sort(function(a, b){
        return (a.order > b.order) ? 1 : -1;
      });

    }

  }

}

</script>