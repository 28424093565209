import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vueVimeoPlayer from 'vue-vimeo-player'
import { registerLicense } from '@syncfusion/ej2-base';

import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'material-symbols';

import './assets/css/style.css';

registerLicense('Ngo9BigBOggjHTQxAR8/V1NHaF5cWWNCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH9fc3VdRGhfV01yX0A=');

Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(VueAxios, axios)
Vue.use(vueVimeoPlayer)

Vue.config.productionTip = false

import routes from './routes'
  
const router = new VueRouter({
  mode: 'history',
  routes  
})

Vue.filter('splitNumber', function (value) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
})

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
 




 