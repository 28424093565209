<template>

  <div class="pt-2">

    <div>

      <b-row align-v="center">

        <b-col lg="12">

          <div class="mt-4">


            <div class="mt-3">

              <b-form-group label="Co chcesz stworzyć?" >
                <b-form-radio-group
                    v-model="typ"
                    :options="typSelect"
                    name="radio-inline"
                    @change="selectedImageParams = []"
                ></b-form-radio-group>
              </b-form-group>

            </div>

            <div class="mt-3">

              <label>Jaki obraz chcesz otrzymać?</label>

              <b-input-group>

                <b-form-input v-model="obraz" size="lg" ref="obraz" placeholder="np. kobieta, gitara, wodospad, danie"></b-form-input>

              </b-input-group>


              <b-card class="mt-3" v-if="(typ === 'Grafika' || typ === 'Zdjęcie')">

                <label>Dodatkowe ustawienia</label>


                <div class="ml-3 mt-3" v-if="(typ === 'Grafika')">

                  <label class="small">Wybierz styl artystyczny:</label>

                  <b-input-group>

                    <b-form-select v-model="styl" ref="styl" :options="stylSelect"></b-form-select>

                  </b-input-group>

                </div>


                <div class="ml-3 mt-3" v-if="(typ === 'Grafika')">

                  <label class="small">Wybierz artystę:</label>

                  <b-input-group>

                    <b-form-select v-model="artysta" ref="artysta" :options="artystaSelect"></b-form-select>

                  </b-input-group>

                </div>


                <div class="ml-3 mt-3" v-if="(typ === 'Zdjęcie')">

                  <label class="small">Wybierz typ aparatu:</label>

                  <b-input-group>

                    <b-form-select v-model="aparat" ref="aparat" :options="aparatSelect"></b-form-select>

                  </b-input-group>

                </div>


                <div class="ml-3 mt-3" v-if="(typ === 'Zdjęcie')">

                  <label class="small">Wybierz obiektyw:</label>

                  <b-input-group>

                    <b-form-select v-model="obiektyw" ref="obiektyw" :options="obiektywSelect"></b-form-select>

                  </b-input-group>

                </div>


                <div class="ml-3 mt-3" v-if="(typ === 'Zdjęcie')">

                  <label class="small">Wybierz kolorystykę:</label>

                  <b-input-group>

                    <b-form-select v-model="kolorystyka" ref="kolorystyka" :options="kolorystykaSelect"></b-form-select>

                  </b-input-group>

                </div>


                <div class="ml-3 mt-3" v-if="(typ === 'Zdjęcie')">

                  <label class="small">Wybierz oświetlenie:</label>

                  <b-input-group>

                    <b-form-select v-model="oswietlenie" ref="oswietlenie" :options="oswietlenieSelect"></b-form-select>

                  </b-input-group>

                </div>


                <b-form-group class="mt-3 ml-3 small" label="Opcjonalne:" v-if="(typ === 'Zdjęcie')">
                  <b-form-checkbox-group
                      id="checkbox-group-1x"
                      v-model="selectedImageParams"
                      :options="imageParams"
                      stacked
                  ></b-form-checkbox-group>
                </b-form-group>

                <b-form-group class="mt-3 ml-3 small" label="Opcjonalne:" v-if="(typ === 'Grafika')">
                  <b-form-checkbox-group
                      id="checkbox-group-1x"
                      v-model="selectedImageParams"
                      :options="graphicParams"
                      stacked
                  ></b-form-checkbox-group>
                </b-form-group>


              </b-card>






              <label class="mt-3 d-none">Możesz również dołączyć zdjęcie, które AI przerobi zgodnie z opisem wyżej</label>

              <b-input-group class="d-none">

                <b-form-file @input="uploadImage()" v-model="customFile" ref="customFile" accept="image/jpeg, image/png, image/gif" placeholder="Kliknij i dodaj zdjęcie" browse-text="Wybierz"></b-form-file>

              </b-input-group>

              <div class="mt-2 ml-2" style='color:#3ad729;' v-if="uploadedFileUrl">
                <span class='material-symbols-outlined align-middle'>task_alt</span> Zdjęcie zostało załadowane <span class="pointer" @click="uploadedFileUrl = null" style="color:#02b404;">[usuń]</span>
              </div>

            </div>

          </div>

        </b-col>

      </b-row>

      <hr/>

      <div v-if="progress" class="mt-2">
         Trwa generowanie obrazów [{{ progress }}%]
        <b-progress class="mt-2" :value="progress" variant="success" striped animated></b-progress>
      </div>

      <b-row align-v="center" class="mt-2" style="min-height: 40px;">

        <b-col lg="6">

        </b-col>

        <b-col lg="6">

          <b-button variant="outline-success mr-1" ref="generatePost" @click="generateFinish()" class="mt-3 float-right">
          <span class="material-symbols-outlined align-middle">
            bolt
          </span>
            Generuj obrazy
          </b-button>

        </b-col>

      </b-row>



      <div v-if="files.length" class="mt-3">Wybierz obraz poniżej, aby przejść do edycji</div>

      <div v-if="files.length" class="row align-items-center justify-content-center">

        <div v-for="file in files" v-bind:key="file">

          <div class="col text-center mt-3 pointer" >

            <router-link :to="('/panel/app/pixel-editor/' + btoa(file))" target="_blank">

              <img :src="file" style="max-width: 460px;height: auto;border-radius: 10px;" />

            </router-link>

          </div>

        </div>

      </div>

    </div>

  </div>

</template>

<script>

export default {
  name: 'PixelAi',
  components: {
  },
  data() {
    return {
      isFinish: false,
      prompt: "",
      selectedText: '',
      query: "",
      task_id: "",
      ppu_id: "",
      customFile: "",
      uploadedFileUrl: "",
      progress: 0,
      files: [],
      obraz: "",
      typ: "Zdjęcie",
      typSelect: [
        "Zdjęcie",
        "Grafika",
        "Własny styl"
      ],
      styl: "BRAK",
      artysta: "BRAK",
      obiektyw: "BRAK",
      kolorystyka: "BRAK",
      aparat: "BRAK",
      oswietlenie: "BRAK",
      selectedImageParams: [],
      imageParams: [
        { text: 'Realistyczna', value: 'realistic' },
        { text: 'Abstrakcyjna', value: 'abstract' },
        { text: 'Wysoka rozdzielczość', value: 'high resolution photograph' },
        { text: 'Czarno-biała', value: 'black and white photograph' },
        { text: 'Wysoki kontrast', value: 'high contrast' },
        { text: 'Skomplikowane szczegóły', value: 'intricate details' },
      ],
      graphicParams: [
        { text: 'Wysoki kontrast', value: 'high contrast' },
      ],
      stylSelect: [
        "BRAK",
        "3D cartoon style",
        "3D render illustration",
        "60s poster art",
        "Abstrakcjonizm",
        "Akwarela",
        "Anime",
        "Art deco",
        "Art nouveau",
        "Barok",
        "Bauhaus",
        "Caricature",
        "Chiaroscuro",
        "Coloring book page for kids",
        "Dadaizm",
        "Dark Fear",
        "Divisionizm",
        "Ekspresjonizm abstrakcyjny",
        "Ekspresjonizm",
        "Enkaustyka",
        "Fantasy",
        "Fauwizm",
        "Flat lay illustration",
        "Fresk",
        "Futuryzm",
        "Gotyk",
        "Gouache",
        "Graffiti",
        "Graphite drawing",
        "Impresjonizm",
        "Impressionism Banksy",
        "Isolated transparent background",
        "Japonizm",
        "Kinetyzm",
        "Klasycyzm",
        "Kolaż",
        "Konstruktywizm",
        "Kubizm",
        "Lego",
        "Malarstwo olejne",
        "Manga",
        "Manieryzm",
        "Minimal futurism",
        "Minimalizm",
        "Modernizm",
        "Muscular marble statue",
        "Neoklasycyzm",
        "Op-art",
        "Orphizm",
        "Pointylizm (Neoimpresjonizm)",
        "Pop-art",
        "Postage stamps",
        "Postać z kreskówki",
        "Postimpresjonizm",
        "Postmodernizm",
        "Prerafaelici",
        "Puzzle do układania",
        "Realizm",
        "Renesans",
        "Rococo",
        "Romanizm",
        "Sfumato",
        "Street wear core graphic",
        "Surrealizm",
        "Symbolizm",
        "Synchromizm",
        "Szkoła z Pont-Aven",
        "Tachizm",
        "Trompe-l’œil",
        "Ukiyo-e",
        "Uroczy i marzycielski",
        "Vector icon",
        "Vintage style",
        "Water color style",
        "Wczesny niderlandzki",
        "Web design",
      ],
      artystaSelect: [
        "BRAK",
        "Albrecht Dürer",
        "Amedeo Modigliani",
        "Andy Warhol",
        "Artemisia Gentileschi",
        "Camille Pissarro",
        "Caravaggio",
        "Claude Monet",
        "Damien Hirst",
        "Diego Velázquez",
        "Edgar Degas",
        "Edvard Munch",
        "Egon Schiele",
        "El Greco",
        "Francisco Goya",
        "Frida Kahlo",
        "Georges Seurat",
        "Giotto di Bondone",
        "Gustav Klimt",
        "Gustave Courbet",
        "Henri Matisse",
        "Henri de Toulouse-Lautrec",
        "Hieronymus Bosch",
        "Jackson Pollock",
        "Jan van Eyck",
        "Jean-Antoine Watteau",
        "Jean-Michel Basquiat",
        "Joan Miró",
        "Johannes Vermeer",
        "Kazimir Malevich",
        "Lucian Freud",
        "Marc Chagall",
        "Mark Rothko",
        "Mary Cassatt",
        "Michelangelo Buonarroti",
        "Pablo Picasso",
        "Paul Cézanne",
        "Paul Gauguin",
        "Peter Paul Rubens",
        "Pierre-Auguste Renoir",
        "Piet Mondrian",
        "Raphael Sanzio",
        "Rembrandt van Rijn",
        "Roy Lichtenstein",
        "Salvador Dalí",
        "Sandro Botticelli",
        "Stephen Shore",
        "Titian (Tiziano Vecellio)",
        "Vincent van Gogh",
        "Wassily Kandinsky",
        "William Turner",
        "Édouard Manet",
        "Leonardo da Vinci",
      ],
      obiektywSelect: [
        "BRAK",
        "Cine lens (obiektyw kinowy)",
        "Mirror lens (obiektyw katadioptryczny)",
        "Obiektyw makro",
        "Obiektyw pancake",
        "Obiektyw panoramiczny",
        "Obiektyw portretowy",
        "Obiektyw stałoogniskowy",
        "Obiektyw tilt-shift",
        "Obiektyw z autofokusem",
        "Obiektyw z efektem bokeh",
        "Obiektyw z efektem soft focus",
        "Obiektyw z manualnym fokusem",
        "Obiektyw z przysłoną stałą",
        "Obiektyw z przysłoną zmienną",
        "Obiektyw z stabilizacją obrazu",
        "Obiektyw zmiennoogniskowy",
        "Obiektyw średnioformatowy",
        "Photo selfie",
        "Rybie oko (Fisheye)",
        "Standardowy",
        "Superteleobiektyw",
        "Teleobiektyw",
        "Ultra szerokokątny",
        "Zoom szerokokątny",
        "Zoom teleobiektyw",
        "Zoom",
      ],
      kolorystykaSelect: [
        "BRAK",
        "Analogowy/nostalgiczny",
        "Bleach bypass",
        "Ciepłe tony",
        "Cross-processing",
        "Cyberpunk / Neon",
        "Czarno-biały",
        "Desaturacja selektywna",
        "Dreamy / Ethereal",
        "Duoton",
        "Dzień vs. Noc",
        "Efekt 'bleed' koloru",
        "Efekt Dragan",
        "Efekt Instagram",
        "Efekt Lomo",
        "Efekt Ortona",
        "Filmowy ziarnisty",
        "Filtrowanie kolorów w czerni i bieli",
        "HDR (High Dynamic Range)",
        "HSL (Hue, Saturation, Lightness) regulacja",
        "High-key",
        "Infrared / Falszywe kolory",
        "Kolor podziału tonów",
        "Kolorowanie ręczne",
        "Look kinowy",
        "Low-key",
        "Matowe cienie",
        "Naturalne oświetlenie",
        "Niebieska godzina",
        "Niskie nasycenie",
        "Pastelowy",
        "Sepia",
        "Smoky / Foggy look",
        "Solarizacja",
        "Sztuczne oświetlenie",
        "Słoneczne światło",
        "Teal and Orange",
        "Toning cieni",
        "Toning cyjan",
        "Toning magenta",
        "Toning naświetlania",
        "Toning niebieski",
        "Toning pomarańczowy",
        "Toning zielony",
        "Vintage / Retro",
        "Wibrujące kolory",
        "Wysokie nasycenie",
        "Zimne tony",
        "Złota godzina",
        "Światło wschodzące / zachodzące",
        "Monochromatyczny",
      ],
      aparatSelect: [
        "BRAK",
        "Bronica SQ-A",
        "Canon AE-1",
        "Canon EOS 1D Mark II",
        "Canon EOS 5D",
        "Canon F-1",
        "Contax G2",
        "Contax T2",
        "Diana F+",
        "Fuji GW690",
        "Fujifilm GA645",
        "Fujifilm X100",
        "Hasselblad 500C",
        "Hasselblad X1D",
        "Kodak Brownie",
        "Konica Hexar RF",
        "Leica CL",
        "Leica Digilux 2",
        "Leica II",
        "Leica M3",
        "Leica Q (Typ 116)",
        "Linhof Technika",
        "Lomo LC-A",
        "Mamiya RB67",
        "Minolta X-700",
        "Minox B",
        "Nikon D1",
        "Nikon D850",
        "Nikon F",
        "Nikon FM2",
        "Nikonos V",
        "Olympus OM-1",
        "Olympus PEN",
        "Olympus Trip 35",
        "Olympus XA",
        "Panasonic Lumix DMC-GH4",
        "Pentax 67",
        "Pentax K1000",
        "Pentax Spotmatic",
        "Phase One XF 100MP",
        "Polaroid SX-70",
        "Ricoh GR Digital",
        "Rolleiflex TLR",
        "Sigma DP1",
        "Sony Cyber-shot DSC-RX100",
        "Sony α7R IV",
        "Sony α9",
        "Voigtländer Bessa",
        "Yashica Mat-124G",
        "Zenza Bronica ETRS",
      ],
      oswietlenieSelect: [
        "BRAK",
        "Światło HMI (Hydrargyrum Medium-Arc Iodide)",
        "Światło High Key",
        "Światło LED",
        "Światło Low Key",
        "Światło akcentowe",
        "Światło bezpośrednie",
        "Światło boczne",
        "Światło błyskowe",
        "Światło ciągłe",
        "Światło dyfuzyjne",
        "Światło fluorescencyjne",
        "Światło główne",
        "Światło kierunkowe",
        "Światło kolorowe (z żelami)",
        "Światło kontrastowe",
        "Światło kształtujące",
        "Światło lampy błyskowej",
        "Światło miękkie",
        "Światło modelujące",
        "Światło naturalne",
        "Światło niebieskiej godziny",
        "Światło odbite od beauty dish",
        "Światło odbite od parasola",
        "Światło odbite od softboxa",
        "Światło odbite",
        "Światło okienne",
        "Światło podkreślające",
        "Światło podłogowe",
        "Światło przednie",
        "Światło punktowe",
        "Światło płaskie",
        "Światło ringowe (ring light)",
        "Światło rozproszone",
        "Światło spektralne",
        "Światło stroboskopowe",
        "Światło studyjne",
        "Światło słoneczne",
        "Światło teatralne",
        "Światło twarde",
        "Światło tylne",
        "Światło wypełniające",
        "Światło z barndoors",
        "Światło z blendy",
        "Światło z gridem (plaster miodu)",
        "Światło z snoota",
        "Światło z żaluzji (gobo)",
        "Światło zastane",
        "Światło zrównoważone (zgodne z temperaturą barwową)",
        "Światło złotej godziny",
        "Światło świecowe",
      ],
      finishContent: '',
      selectedSchema: null
    }
  },
  methods: {

    btoa: function (txt) {

      return btoa(txt)

    },

    uploadImage: function () {

      if(!this.customFile) return false;

      const formData = new FormData();

      formData.append('file', this.$refs['customFile'].files[0]);

      const headers = { 'Content-Type': 'multipart/form-data' };

      this.axios.post("https://edunso.pl/api/images/upload/", formData, { withCredentials: true, headers }).then((response) => {

        let data = response.data

        this.uploadedFileUrl = data.file

        if (this.uploadedFileUrl) {

          this.customFile = null
        }

      });

    },

    cancel: function () {

      this.task_id = ""
      this.ppu_id = ""
      this.progress = 0

    },

    /*check: function () {

      if(this.task_id) {

        this.axios.post(window.API_URL + "images/check", { 'task_id': this.task_id, 'ppu_id': this.ppu_id }, { withCredentials: true, timeout: 3000000 }).then((response) => {

          if(this.task_id) {

            let data = response.data

            if (data.redirectTo) {

              top.location.href = data.redirectTo;

            }

            if (data.files) {

              this.files = data.files

              this.cancel()

              this.$refs.generatePost.disabled = false

            }

            if (data.progress) {

              this.progress = data.progress

            }

          }

        }).catch(() => {

          this.$refs.generatePost.disabled = false

        })

      }

    }, */

    generateFinish: function() {

      if(!this.obraz) return false;

      this.$refs.generatePost.disabled = true
      this.files = []
      this.progress = 10;

      var query = this.obraz;
      var typeOfImage = "";

      if(!this.uploadedFileUrl)
        this.uploadedFileUrl = ''

      if (this.typ === "Zdjęcie") {

        typeOfImage = "zdjęć"

        query = "fotografię " + query + "."

        if(this.aparat !== "BRAK")
          query += "Wykonaj ją aparatem " + this.aparat + ". ";

        if(this.obiektyw !== "BRAK")
          query += "Użyj obiektywu " + this.obiektyw + ". ";

        if(this.kolorystyka !== "BRAK")
          query += "Zastosuj kolorystykę " + this.kolorystyka + ". ";

        if(this.oswietlenie !== "BRAK")
          query += "Zastosuj oświetlenie " + this.oswietlenie + ". ";

      }


      if (this.typ === "Grafika") {

        typeOfImage = "grafiki"

        query = "grafikę " + query + ". ";

        if(this.styl !== "BRAK")
          query += "Grafikę wykonaj w stylu " + this.styl;

        if(this.artysta !== "BRAK")
          query += " imitując styl artystyczny " + this.artysta;

      }

      query = query + ". ";

      this.selectedImageParams.forEach((element) => {

        query = query + element + ", "

      });

      query = "Chcę wygenerować: \"" + query + "\". Stwórz szczegółowy prompt (maksymalnie 1500 znaków) do generatora " + typeOfImage + " AI. Opisz wszystko począwszy od podmiotu, przez otoczenie, tło i styl. Zrób to w języku angielskim.";


      this.axios.post(window.API_URL + "text/generateFromAI/", { 'text': query }, { withCredentials: true, timeout: 3000000 }).then((response) => {

        let data = response.data

        if(data.redirectTo) {

          top.location.href = data.redirectTo;

        }

        this.prompt = data.result;

        this.progress = 20;

        this.axios.post(window.API_URL + "images/generateSimple/", { 'prompt': this.prompt, 'file': this.uploadedFileUrl }, { withCredentials: true, timeout: 3000000 }).then((response) => {

          let data = response.data



          if (data.files) {

            this.files = data.files

            this.cancel()

            this.$refs.generatePost.disabled = false


            //if(data.messageId) {

            //this.task_id = data.messageId

            //if(data.ppuId) {
            //this.ppu_id = data.ppuId
            //}

            //this.progress = 10;

            //setInterval(() => this.check(), 10000);
          // }


          } else {

            // error
            this.$refs.generatePost.disabled = false
            this.progress = 0;

          }

        }).catch(() => {

          this.$refs.generatePost.disabled = false
          this.progress = 0;

        })


      }).catch(function() {

        this.$refs.generatePost.disabled = false
        this.progress = 0;

      })



    }

  },
  computed: {

  },
  created() {


  }

}
</script>

<style>

.pointer { cursor: pointer; }
.bold { font-weight: 600 !important; }
code { color: #4e82f7 !important; }
</style>