<template>

  <div class="pt-2">

    <div class="control-section">
      <div class="col-lg-12 e-img-editor-sample">
        <ejs-imageeditor :theme="themeValue" id="image-editor" ref="imageEditorObj" :created = "created">

        </ejs-imageeditor>
      </div>
    </div>

  </div>

</template>

<script>
import { ImageEditorComponent } from "@syncfusion/ej2-vue-image-editor";
import { Browser, getComponent, isNullOrUndefined, L10n, setCulture } from "@syncfusion/ej2-base";

setCulture('pl-PL');

L10n.load({
  'pl-PL': {
    'image-editor': {
      'Browse': 'Przeglądaj',
      'Crop': 'Kadruj',
      'ZoomIn': 'Powiększ',
      'ZoomOut': 'Pomniejsz',
      'CropAndTransform': 'Kadruj i transformuj',
      'Annotation': 'Wstaw',
      'Ellipse': 'Elipsa',
      'Arrow': 'Strzałka',
      'Path': 'Ścieżka',
      'Image': 'Obraz',
      'Text': 'Tekst',
      'Pen': 'Pióro',
      'Reset': 'Resetuj',
      'Save': 'Zapisz',
      'Select': 'Wybierz',
      'RotateLeft': 'Obróć w lewo',
      'RotateRight': 'Obróć w prawo',
      'HorizontalFlip': 'Odbij w poziomie',
      'VerticalFlip': 'Odbij w pionie',
      'OK': 'OK',
      'Color': 'Kolor',
      'GradientColor': 'Kolor gradientu',
      'Offset': 'Odstęp',
      'None': 'Brak',
      'Filter': 'Filtr',
      'Finetune': 'Dostrajanie',
      'Brightness': 'Jasność',
      'Contrast': 'Kontrast',
      'Hue': 'Barwa',
      'Saturation': 'Nasycenie',
      'Exposure': 'Ekspozycja',
      'Opacity': 'Przezroczystość',
      'Blur': 'Rozmycie',
      'Size': 'Rozmiar',
      'Inset': 'Wstawka',
      'Cancel': 'Anuluj',
      'FillColor': 'Kolor wypełnienia',
      'StrokeColor': 'Kolor obrysu',
      'StrokeWidth': 'Grubość obrysu',
      'FontFamily': 'Czcionka',
      'FontStyle': 'Styl czcionki',
      'FontSize': 'Rozmiar czcionki',
      'FontColor': 'Kolor czcionki',
      'Resize': 'Zmień rozmiar',
      'Frame': 'Ramka',
      'Move': 'Ruch',
      'Custom': 'Niestandardowy',
      'Square': 'Kwadrat',
      'Circle': 'Okrąg',
      'Rectangle': 'Prostokąt',
      'Line': 'Linia',
      'Default': 'Domyślny',
      'Bold': 'Pogrubienie',
      'Italic': 'Kursywa',
      'BoldItalic': 'Pogrubiona kursywa',
      'XSmall': 'Bardzo cienka',
      'Small': 'Cienka',
      'medium': 'Średnia',
      'Large': 'Gruba',
      'XLarge': 'Bardzo gruba',
      'Bar': 'Kostka',
      'ArrowSolid': 'G.Strzałka',
      'CircleSolid': 'G.Okrąg',
      'SquareSolid': 'G.Kwadrat',
      'Chrome': 'Chrom',
      'Cold': 'Zimno',
      'Warm': 'Ciepło',
      'Grayscale': 'Szarość',
      'Sepia': 'Sepia',
      'Invert': 'Odwróć',
      'Undo': 'Cofnij',
      'Redo': 'Ponów',
      'Duplicate': 'Duplikuj',
      'Remove': 'Usuń'
    }
  }
});

export default {
  name: 'PixelAi',
  components: {
    'ejs-imageeditor': ImageEditorComponent
  },
  data() {
    return {
      file: this.$route.params.other
    }
  },
  methods: {

    created: function() {


     if (Browser.isDevice) {
        this.$refs.imageEditorObj.open(atob(this.file));
      } else {
        this.$refs.imageEditorObj.open(atob(this.file));
      }
      if (this.themeValue && window.location.href.split('#')[1]) {
        this.themeValue = window.location.href.split('#')[1].split('/')[1];
      }

    },

  },
  computed: {
    themeValue: {
      get: function () {
        return this.theme;
      },
      set: function (theme) {
        this.theme = theme
      }
    }
  },
  created() {



  },

  onScroll: function() {
    if (document.getElementById('imageeditor_sliderWrapper')) {
       var slider = getComponent(document.getElementById('imageeditor_sliderWrapper'), 'slider');
       slider.refreshTooltip(slider.tooltipTarget);
     }
  },
  mounted: function() {
    if (!isNullOrUndefined(document.getElementById("right-pane"))) {
      document.getElementById("right-pane").addEventListener("scroll", this.onScroll.bind(this));
    }
  },
  watch: {

    '$route.params.other' (file) {

      this.file = file

    }

  },

}
</script>


<style scoped>

  .e-img-editor-sample {
    height: 80vh;
    width: 100%;
  }

  @media only screen and (max-width: 700px) {
    .e-img-editor-sample {
      height: 75vh;
      width: 100%;
    }
  }

  .control-wrapper {
    height: 100%;
  }

  .e-image-editor {
    margin: 0 auto;
  }

  .pointer { cursor: pointer; }
  .bold { font-weight: 600 !important; }
  code { color: #4e82f7 !important; }

</style>