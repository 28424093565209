<template>

  <div class="Panel bg-white">

    <NavBar
        :user="user"
    />

    <div class="">

      <b-row>

        <b-col cols="12" lg="2" class="p-3" style="border-right: 1px solid #e9e9e9;background-color: #f9f9f9;">

          <b-list-group>
            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel'">
              <span class="material-symbols-outlined align-middle fw-600 icon" style="color:#585bff;">apps</span>
              Aplikacje
            </b-list-group-item>

            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel/kursy'">
              <span class="material-symbols-outlined align-middle fw-600 icon">menu_book</span>
              Kursy
            </b-list-group-item>
          </b-list-group>

        </b-col>

        <b-col cols="12" lg="10" class="p-3 pt-4">

          <b-form-input class="" v-model="search" placeholder="Szukaj aplikacji..."></b-form-input>

          <b-row class="d-flex">

            <b-col class="mt-3 d-flex" lg="3" sm="6" cols="6" v-for="item in sortedItems()" :key="item.slug">

              <b-card no-body class="overflow-hidden" :class="{ 'disabled-app': !user.permissions.includes(item.neededPermissions) }">

                <b-card-body>

                  <img :src="require('@/assets/img/apps/' + item.cover)" style="width:100%;" class="mb-3"/>

                  <h6 class="font-weight-bold" v-if="item.isBestseller">
                    <b-link :to="('/panel/app/' + item.slug + '/')" class="text-dark">
                      <b-badge variant="success">BESTSELLER</b-badge>
                    </b-link>
                  </h6>

                  <b-card-text class="small">

                    <div class="text-center mb-2" style="color:#6e6e6e;">{{ item.subtitle }}</div>

                    <div style="color: #898989;" v-if="item.showMetaData">
                      <span class="material-symbols-sharp align-middle" style="font-size: 1rem;">widgets</span> &nbsp; {{ item.addons.length }} dodatków<br/>
                    </div>

                    <div style="height:100%;">
                      <div v-if="user.permissions.includes(item.neededPermissions)" class="text-center text-success">
                        <b-button class="mt-2" variant="light" :to="('/panel/app/' + item.slug + '/')" block size="sm" v-if="!item.isOldApp">Przejdź do aplikacji</b-button>
                        <b-button class="mt-2" variant="light" :href="(item.oldLink)" block size="sm" v-if="item.isOldApp">Przejdź do aplikacji</b-button>
                      </div>

                      <div v-if="!user.permissions.includes(item.neededPermissions)" class="text-center text-danger">
                        <span class="material-symbols-sharp align-middle" style="font-size: 1rem;">info</span> Nie masz dostępu

                        <b-button v-if="item.buyLink" class="d-none mt-2" variant="success" :href="(item.buyLink)" block size="sm">Uzyskaj dostęp</b-button>
                      </div>
                    </div>

                  </b-card-text>

                </b-card-body>

              </b-card>

            </b-col>

          </b-row>


        </b-col>

      </b-row>

    </div>

    <Footer/>

  </div>

</template>

<script>

import Footer from '@/elements/Footer';
import NavBar from "@/elements/Navbar/index.vue";

export default {

  name: 'Panel',

  components: {
    NavBar, Footer
  },

  data() {

    return {
      apps: [],
      sortBy: 'order',
      sortDirection: 'asc',
      search: '',
      user: {
        id: null,
        permissions: []
      }
    }

  },

  created() {

    this.axios.get(window.API_URL + "user", { withCredentials: true }).then((response) => {

      let data = response.data

      if(data.redirectTo) {

        top.location.href = data.redirectTo;

      }

      if(data.userId) {

        this.user.id = data.userId
        this.user.permissions = data.permissions


        this.axios.get(window.API_URL + "apps/getAllApps").then((response) => {

          let data = response.data

          data.data.forEach(element => {

            this.axios.get(window.API_URL + "apps/getApp/" + element.slug).then((response) => {

              var add = response.data;
              add.order = element.order;

              add.neededPermissions = element.neededPermissions;

              if(!add.isHidden) {

                if(add.showOnlyIfHasAPerms) {

                  if(this.user.permissions.includes(add.neededPermissions)) {

                    this.apps.push(add)

                  }

                } else {

                  this.apps.push(add)

                }

              }

            })

          })

        })


      }

    });

  },

  methods: {

    sortedItems: function (){

      var search = this.search.toLowerCase();

      var arr = this.apps;

      if(search) {

        arr = arr.map(object => {
          object.name = object.name.toLowerCase()
          return object;
        });

        arr = arr.filter(function(el) {
          return el.name.indexOf(search) > -1
        });

      }

      return arr.slice().sort(function(a, b){
        return (a.order > b.order) ? 1 : -1;
      });

    }

  }

}

</script>