<template>

  <div class="pt-2">

    <div>

      <b-row align-v="center">

        <b-col lg="12">

          <div class="mt-4">

            <div class="mt-3">

              <b-form-group label="Co chcesz stworzyć?" >
                <b-form-radio-group
                    v-model="typ"
                    :options="typSelect"
                    name="radio-inline"
                ></b-form-radio-group>
              </b-form-group>

            </div>

            <div class="mt-3">

              <label>Jaki obraz chcesz otrzymać?</label>

              <b-input-group>

                <b-form-input v-model="obraz" size="lg" ref="obraz" placeholder="np. kobieta, gitara, wodospad, danie"></b-form-input>

              </b-input-group>

            </div>

            <div class="mt-3" v-if="(typ === 'Grafikę')">

              <label>Wybierz styl artystyczny:</label>

              <b-input-group>

                <b-form-select v-model="styl" ref="styl" :options="stylSelect"></b-form-select>

              </b-input-group>

            </div>

            <div class="mt-3" v-if="(typ === 'Grafikę')">

              <label>Wybierz artystę:</label>

              <b-input-group>

                <b-form-select v-model="artysta" ref="artysta" :options="artystaSelect"></b-form-select>

              </b-input-group>

            </div>

            <div class="mt-3" v-if="(typ === 'Obraz')">

              <label>Wybierz typ aparatu:</label>

              <b-input-group>

                <b-form-select v-model="aparat" ref="aparat" :options="aparatSelect"></b-form-select>

              </b-input-group>

            </div>

            <div class="mt-3" v-if="(typ === 'Obraz')">

              <label>Wybierz obiektyw:</label>

              <b-input-group>

                <b-form-select v-model="obiektyw" ref="obiektyw" :options="obiektywSelect"></b-form-select>

              </b-input-group>

            </div>

            <div class="mt-3" v-if="(typ === 'Obraz')">

              <label>Wybierz kolorystykę:</label>

              <b-input-group>

                <b-form-select v-model="kolorystyka" ref="kolorystyka" :options="kolorystykaSelect"></b-form-select>

              </b-input-group>

            </div>

            <div class="mt-3" v-if="(typ === 'Obraz')">

              <label>Wybierz oświetlenie:</label>

              <b-input-group>

                <b-form-select v-model="oswietlenie" ref="oswietlenie" :options="oswietlenieSelect"></b-form-select>

              </b-input-group>

            </div>

          </div>

        </b-col>

      </b-row>

      <hr/>

      <b-row align-v="center" class="mt-2" style="min-height: 40px;">

        <b-col lg="6">

        </b-col>

        <b-col lg="6">

          <b-button variant="outline-success mr-1" ref="generatePost" @click="generateFinish()" class="mt-3 float-right">
          <span class="material-symbols-outlined align-middle">
            bolt
          </span>
            Generuj prompt
          </b-button>

        </b-col>

      </b-row>



      <div class="mt-2" >

        <b-card header-tag="header" class="mt-4" v-if="isFinish">

          <template #header>

            <h6 class="mb-0 mt-2 float-left">
              <span v-if="!finishContent"><b-spinner variant='secondary' small></b-spinner> AI generuje prompt <small>(może to potrwać dłuższą chwilę. Nie odświeżaj strony!)</small></span>
              <span v-if="finishContent">Wygenerowany prompt</span>
            </h6>

            <b-button class="float-right p-0" variant="outline-white" @click="isFinish = false">

              <span class="material-symbols-outlined mt-1">close</span>

            </b-button>

          </template>

          <blockquote class="blockquote mb-0" v-if="result" style="font-size: 1em;">

            <div v-html="result" class="mb-3 mt-3 p-3" style="border: 1px dashed #f3f3f3; font-size: 1em; line-height: 1.7em;"></div>

            <div class="text-primary pointer small mt-1 float-right" @click="copyResults">

              <span class="material-symbols-sharp align-middle">content_copy</span>
              Kopiuj do schowka

            </div>

          </blockquote>

        </b-card>

        <textarea ref="selectedText" style="display: none;" v-model="selectedText" v-on:focus="$event.target.select()"></textarea>
        <textarea ref="finishContent" style="display: none;" v-model="finishContent" v-on:focus="$event.target.select()"></textarea>

      </div>

    </div>

  </div>

</template>

<script>

export default {
  name: 'MidjourneyPromptGenerator',
  components: {
  },
  data() {
    return {
      isFinish: false,
      result: "",
      selectedText: '',
      query: "",
      obraz: "",
      typ: "Grafikę",
      styl: "BRAK",
      artysta: "BRAK",
      obiektyw: "BRAK",
      kolorystyka: "BRAK",
      aparat: "BRAK",
      oswietlenie: "BRAK",
      typSelect: [
        "Grafikę",
        "Obraz"
      ],
      stylSelect: [
        "BRAK",
        "Abstrakcjonizm",
        "Art deco",
        "Art nouveau",
        "Barok",
        "Bauhaus",
        "Dadaizm",
        "Ekspresjonizm",
        "Ekspresjonizm abstrakcyjny",
        "Fauwizm",
        "Futuryzm",
        "Gotyk",
        "Graffiti",
        "Impresjonizm",
        "Japonizm",
        "Klasycyzm",
        "Konstruktywizm",
        "Kubizm",
        "Manieryzm",
        "Minimalizm",
        "Modernizm",
        "Neoklasycyzm",
        "Op-art",
        "Orphizm",
        "Pointylizm (Neoimpresjonizm)",
        "Pop-art",
        "Postać z kreskówki",
        "Postimpresjonizm",
        "Postmodernizm",
        "Prerafaelici",
        "Realizm",
        "Renesans",
        "Rococo",
        "Romanizm",
        "Sfumato",
        "Surrealizm",
        "Symbolizm",
        "Synchromizm",
        "Szkoła z Pont-Aven",
        "Tachizm",
        "Trompe-l’œil",
        "Ukiyo-e",
        "Wczesny niderlandzki",
        "Akwarela",
        "Chiaroscuro",
        "Divisionizm",
        "Enkaustyka",
        "Fresk",
        "Gouache",
        "Kinetyzm",
        "Kolaż",
        "Malarstwo olejne"
      ],
      artystaSelect: [
        "BRAK",
        "Leonardo da Vinci",
        "Michelangelo Buonarroti",
        "Raphael Sanzio",
        "Sandro Botticelli",
        "Caravaggio",
        "Rembrandt van Rijn",
        "Johannes Vermeer",
        "Vincent van Gogh",
        "Pablo Picasso",
        "Claude Monet",
        "Pierre-Auguste Renoir",
        "Paul Cézanne",
        "Edgar Degas",
        "Henri Matisse",
        "Paul Gauguin",
        "Gustav Klimt",
        "Salvador Dalí",
        "Jackson Pollock",
        "Andy Warhol",
        "Francisco Goya",
        "Jan van Eyck",
        "Albrecht Dürer",
        "Peter Paul Rubens",
        "Diego Velázquez",
        "Édouard Manet",
        "Georges Seurat",
        "Joan Miró",
        "Marc Chagall",
        "Wassily Kandinsky",
        "Piet Mondrian",
        "Frida Kahlo",
        "Titian (Tiziano Vecellio)",
        "Hieronymus Bosch",
        "El Greco",
        "Gustave Courbet",
        "Jean-Michel Basquiat",
        "Damien Hirst",
        "Kazimir Malevich",
        "Henri de Toulouse-Lautrec",
        "Egon Schiele",
        "Edvard Munch",
        "William Turner",
        "Camille Pissarro",
        "Amedeo Modigliani",
        "Lucian Freud",
        "Roy Lichtenstein",
        "Jean-Antoine Watteau",
        "Artemisia Gentileschi",
        "Giotto di Bondone",
        "Mary Cassatt"
      ],
      obiektywSelect: [
        "BRAK",
        "Szerokokątny",
        "Ultra szerokokątny",
        "Rybie oko (Fisheye)",
        "Standardowy",
        "Teleobiektyw",
        "Superteleobiektyw",
        "Zoom",
        "Zoom szerokokątny",
        "Zoom teleobiektyw",
        "Obiektyw makro",
        "Obiektyw portretowy",
        "Obiektyw tilt-shift",
        "Obiektyw pancake",
        "Obiektyw z manualnym fokusem",
        "Obiektyw z autofokusem",
        "Obiektyw stałoogniskowy",
        "Obiektyw zmiennoogniskowy",
        "Cine lens (obiektyw kinowy)",
        "Obiektyw z przysłoną stałą",
        "Obiektyw z przysłoną zmienną",
        "Obiektyw z stabilizacją obrazu",
        "Obiektyw średnioformatowy",
        "Obiektyw z efektem bokeh",
        "Obiektyw z efektem soft focus",
        "Mirror lens (obiektyw katadioptryczny)",
        "Obiektyw panoramiczny"
      ],
      kolorystykaSelect: [
        "BRAK",
        "Monochromatyczny",
        "Sepia",
        "Czarno-biały",
        "Pastelowy",
        "Wysokie nasycenie",
        "Niskie nasycenie",
        "Cross-processing",
        "Analogowy/nostalgiczny",
        "Bleach bypass",
        "Desaturacja selektywna",
        "Kolor podziału tonów",
        "HDR (High Dynamic Range)",
        "Kolorowanie ręczne",
        "Vintage / Retro",
        "Look kinowy",
        "Ciepłe tony",
        "Zimne tony",
        "Słoneczne światło",
        "Złota godzina",
        "Niebieska godzina",
        "Toning naświetlania",
        "Toning cieni",
        "High-key",
        "Low-key",
        "Wibrujące kolory",
        "Matowe cienie",
        "Efekt Lomo",
        "Efekt Instagram",
        "Sztuczne oświetlenie",
        "Naturalne oświetlenie",
        "Dzień vs. Noc",
        "Toning zielony",
        "Toning niebieski",
        "Toning pomarańczowy",
        "Dreamy / Ethereal",
        "Filmowy ziarnisty",
        "Teal and Orange",
        "Cyberpunk / Neon",
        "Infrared / Falszywe kolory",
        "Solarizacja",
        "Duoton",
        "HSL (Hue, Saturation, Lightness) regulacja",
        "Efekt Ortona",
        "Efekt Dragan",
        "Światło wschodzące / zachodzące",
        "Toning magenta",
        "Toning cyjan",
        "Filtrowanie kolorów w czerni i bieli",
        "Smoky / Foggy look",
        "Efekt 'bleed' koloru"
      ],
      aparatSelect: [
        "BRAK",
        "Leica M3",
        "Nikon F",
        "Canon AE-1",
        "Hasselblad 500C",
        "Pentax K1000",
        "Olympus OM-1",
        "Rolleiflex TLR",
        "Polaroid SX-70",
        "Mamiya RB67",
        "Yashica Mat-124G",
        "Canon F-1",
        "Minolta X-700",
        "Nikon D1",
        "Kodak Brownie",
        "Contax T2",
        "Olympus PEN",
        "Fujifilm X100",
        "Sony α9",
        "Leica Q (Typ 116)",
        "Hasselblad X1D",
        "Canon EOS 5D",
        "Nikon D850",
        "Phase One XF 100MP",
        "Lomo LC-A",
        "Leica CL",
        "Leica II",
        "Canon EOS 1D Mark II",
        "Nikon FM2",
        "Pentax 67",
        "Voigtländer Bessa",
        "Diana F+",
        "Olympus Trip 35",
        "Konica Hexar RF",
        "Bronica SQ-A",
        "Leica Digilux 2",
        "Ricoh GR Digital",
        "Nikonos V",
        "Sigma DP1",
        "Fujifilm GA645",
        "Linhof Technika",
        "Minox B",
        "Sony Cyber-shot DSC-RX100",
        "Panasonic Lumix DMC-GH4",
        "Zenza Bronica ETRS",
        "Contax G2",
        "Pentax Spotmatic",
        "Fuji GW690",
        "Sony α7R IV",
        "Olympus XA"
      ],
      oswietlenieSelect: [
        "BRAK",
        "Światło naturalne",
        "Światło słoneczne",
        "Światło dyfuzyjne",
        "Światło bezpośrednie",
        "Światło odbite",
        "Światło tylne",
        "Światło boczne",
        "Światło przednie",
        "Światło miękkie",
        "Światło twarde",
        "Światło złotej godziny",
        "Światło niebieskiej godziny",
        "Światło kierunkowe",
        "Światło płaskie",
        "Światło kontrastowe",
        "Światło ringowe (ring light)",
        "Światło punktowe",
        "Światło rozproszone",
        "Światło studyjne",
        "Światło ciągłe",
        "Światło stroboskopowe",
        "Światło błyskowe",
        "Światło lampy błyskowej",
        "Światło LED",
        "Światło HMI (Hydrargyrum Medium-Arc Iodide)",
        "Światło fluorescencyjne",
        "Światło spektralne",
        "Światło zrównoważone (zgodne z temperaturą barwową)",
        "Światło zastane",
        "Światło odbite od parasola",
        "Światło odbite od softboxa",
        "Światło odbite od beauty dish",
        "Światło z gridem (plaster miodu)",
        "Światło z snoota",
        "Światło z żaluzji (gobo)",
        "Światło z barndoors",
        "Światło okienne",
        "Światło z blendy",
        "Światło wypełniające",
        "Światło główne",
        "Światło akcentowe",
        "Światło podkreślające",
        "Światło kształtujące",
        "Światło modelujące",
        "Światło Low Key",
        "Światło High Key",
        "Światło podłogowe",
        "Światło świecowe",
        "Światło teatralne",
        "Światło kolorowe (z żelami)"
      ],
      finishContent: '',
      selectedSchema: null
    }
  },
  methods: {

    generateFinish: function() {

      if(!this.obraz) return false;


      this.$refs.generatePost.disabled = true

      this.isFinish = true

      this.finishContent = ""
      this.result = ""

      var query = "";


      if(this.typ === "Grafikę") {

        query = "Stwórz grafikę przedstawiającą " + this.obraz + ".";

        if(this.styl !== "BRAK")
          query += "Grafikę wykonaj w stylu " + this.styl;

        if(this.artysta !== "BRAK")
          query += " imitując styl artystyczny " + this.artysta;

      }

      if(this.typ === "Obraz") {

        query = "Stwórz realistyczne zdjęcie " + this.obraz;

        if(this.aparat !== "BRAK")
          query += " wykonane aparatem " + this.aparat;

        if(this.obiektyw !== "BRAK")
          query += " i obiektywem " + this.obiektyw;

        if(this.kolorystyka !== "BRAK")
          query += " w kolorystyce " + this.kolorystyka;

        if(this.oswietlenie !== "BRAK")
          query += " z oświetleniem " + this.oswietlenie;

      }

      query = "Przetłumacz na język angielski: \"" + query + "\"";

      var doneText = "";

      this.axios.post(window.API_URL + "text/generateFromAI/", { 'text': query }, { withCredentials: true, timeout: 3000000 }).then((response) => {

        let data = response.data

        if(data.redirectTo) {

          top.location.href = data.redirectTo;

        }

        doneText = data.result;

        this.finishContent = doneText;

        this.result = doneText.replace(/(?:\r\n|\r|\n)/g, '<br/>');

        this.$refs.generatePost.disabled = false
        this.generatedPostTitle = "Wygenerowany prompt"

      }).catch(function() {

        this.$refs.generatePost.disabled = false

      })



    },

    copyResults: function() {

      this.$refs.finishContent.style.display = "block";

      this.$refs.finishContent.focus();

      document.execCommand('copy');

      this.$refs.finishContent.style.display = "none"

      window.scrollTo(0, 0)

      this.$bvToast.toast('Treść została skopiowana do schowka', {
        title: 'Skopiowano',
        autoHideDelay: 5000,
        variant: 'success',
        solid: true,
        toaster: 'b-toaster-top-center',
        noCloseButton: true
      })

    }

  },
  computed: {

  },
  created() {

    this.$watch('finishContent', (value) => {

      var lines = value.split(/\r|\r\n|\n/);

      this.finishLinesCount = lines.length;

    })

  }

}
</script>

<style>

.pointer { cursor: pointer; }
.bold { font-weight: 600 !important; }
code { color: #4e82f7 !important; }
</style>