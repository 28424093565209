<template> 

  <div class="p-2 pl-4 small" style="background-color: #01071a;color:#a2df2f;">

    👋🏼 Sprawdź co jeszcze mamy w ofercie. 👉🏼 <router-link :to="'/pakiety'" class="text-white">Kliknij tutaj</router-link>

  </div>
</template>

<script>
export default {
  name: 'NotifyBar',
  props: {

  }
}
</script>

 