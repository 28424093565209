<template> 

  <div class="Course bg-white">



    <NavBar
      :user="user"
    />

    <MiddleBar
      v-bind="data"
      :author="author"
    />
    <NotifyBar />
    <div class="pt-4">

      <b-row>

        <b-col cols="12" lg="6" class="text-content">
          <ul class="nav nav-underline">
            <li class="nav-item">
              <a class="nav-link active" href="#" v-if="(data.type === 'package')">O pakiecie</a>
              <a class="nav-link active" href="#" v-if="(data.type === 'course')">O kursie</a>
            </li>
            <li class="nav-item" v-if="data.modules.length > 0">
              <a class="nav-link" href="#zawartosc">Zawartość</a>
            </li>
          </ul>

          <div class="p-3 mt-3" v-html="offerContent"></div>

        </b-col>

        <b-col cols="12" lg="6">

          <CourseRightBar
            v-bind="data"
          />

        </b-col>

      </b-row>

    </div>


    <div class="section-second pt-5 pb-5 pl-1 text-content" v-if="data.modules.length > 0">

      <p id="zawartosc"></p>

      <h3 class="ml-3">Zawartość</h3>

      <b-row class="mt-3">

        <b-col cols="12" lg="6">

            <b-list-group class="includes-group p-0">

              <span v-for="(module, index) in data.modules" v-bind:key="module.id">

                <b-list-group-item href="#" class="fw-500 mt-2 p-4 module">{{ (index+1) }}. {{ module.name }}</b-list-group-item>

                <span v-for="lesson in data.lessons" v-bind:key="lesson.id">

                  <b-list-group-item v-if="lesson.module === module.id" href="#" class="p-3 pl-4 lesson">

                    <span v-if="!lesson.free" class="material-symbols-outlined align-middle fw-600 icon">lock</span>
                    <span v-if="lesson.free" class="material-symbols-outlined align-middle fw-600 icon">visibility</span>

                    {{ lesson.name }}

                    <div v-if="lesson.free" class="free-lesson">Darmowa lekcja</div>
                    <div v-if="lesson.free" class="clear"></div>

                  </b-list-group-item>

                </span>

              </span>

            </b-list-group>

        </b-col>

        <b-col cols="12" lg="6">

        </b-col>

      </b-row>

    </div>

    <Footer/>

  </div>

</template>

<script>

  import CourseRightBar from '@/elements/CourseRightBar';
  import MiddleBar from '@/elements/MiddleBar';
  import Footer from '@/elements/Footer';
  import NavBar from "@/elements/Navbar/index.vue";
  import NotifyBar from "@/elements/NotifyBar/index.vue";

  export default {

    name: 'Course',

    components: {
      NavBar, CourseRightBar, MiddleBar, Footer, NotifyBar
    },

    data() {

      return {
        data: {},
        offerContent: '',
        author: {},
        user: {
          id: null,
          permissions: []
        }
      }

    },

    created() {

      this.axios.get(window.API_URL + "user", { withCredentials: true }).then((response) => {

        let data = response.data

        if (data.userId) {

          this.user.id = data.userId
          this.user.permissions = data.permissions

        }

      });

      if(this.$route.params.slug) {

        this.load(this.$route.params.slug)

      }

    },

    methods: {

      load: function(slug) {

        if (slug) {

          this.axios.get(window.API_URL + "courses/get/" + slug, {withCredentials: true}).then((response) => {

            this.data = response.data

            this.axios.get(window.API_URL + "courses/getOffer/" + slug, {withCredentials: true}).then((response) => {

              this.offerContent = response.data

            });

            this.axios.get(window.API_URL + "authors/get/" + this.data.author, {withCredentials: true}).then((response) => {

              this.author = response.data

            });

          });

        }

      }

    }

  }
  
</script>