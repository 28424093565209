<template> 
  <div class="bg-white">

    <NavBar :user="user"/>
    <MiddleBarStaticPages title="Regulamin"/>

    <div class="container pt-4">

      <div class="p-3">



        <p><strong>REGULAMIN SERWISU EDUNSO.pl</strong></p>

        <BR/><BR/><p><strong>Postanowienia ogólne</strong></p>

        <BR/>1. Niniejszy Regulamin określa zakres i zasady świadczenia drogą elektroniczną Usługi Dostępu do Kursów i Aplikacji przez AGAI Spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie (00-019 Warszawa, ul. Złota 7/28) KRS: 0000847307, NIP 6762582015, REGON 386356611, o kapitale zakładowym w wysokości 5 000 zł za pośrednictwem Serwisu zlokalizowanego pod adresem: https://edunso.pl/ i innych subdomenach edunso.pl.
        <BR/>2. Świadczenie Usługi odbywa się za pomocą Serwisu na zasadach określonych w niniejszym Regulaminie oraz Regulaminie Serwisu Edunso.pl.
        <BR/>3. W przypadku rozbieżności w postanowieniach nin. Regulaminu oraz Regulaminu Serwisu Edunso.pl, pierwszeństwo mają postanowienia nin. Regulaminu.
        <BR/>4. O ile Regulamin nie stanowi inaczej, wszelkie oświadczenia woli, zawiadomienia i kontakty pomiędzy Usługodawcą a Usługobiorcą następują w formie elektronicznej, poprzez adres email Usługodawcy: info@edunso.pl. W przypadku zmiany adresu email lub sposobu kontaktu wskazanego w Regulaminie Usługodawca poinformuje o tym Usługobiorcę e-mailowo na adres podany przez Usługobiorcę przy procesie zamówienia Usługi.
        <BR/>5. Usługobiorca poprzez złożenie zamówienia na Usługę opisaną w niniejszym Regulaminie w sposób przewidziany w Regulaminie, potwierdza jednocześnie, że zapoznał się z Regulaminem i Regulaminu Serwisu Edunso.pl oraz, że akceptuje jego postanowienia. Złożenie zamówienia i zapłata za Usługę stanowi zawarcie umowy o świadczenie usług drogą elektroniczną w rozumieniu ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (Dz.U. 2002 nr 144 poz. 1204), zwana dalej u.ś.u.d.e.

        <BR/><BR/><p><strong>I. Definicje</strong></p>

        <BR/>1. Panel Usługobiorcy – indywidualnie przypisane dla danego Usługobiorcy konto umożliwiające mu czynności administracyjne związane z Usługą, a to m.in.: zmianę hasła, pobranie faktury, uruchomienie Kursów i Aplikacji, zapoznanie się z inną ofertą Usługodawcy.
        <BR/>2. Usługa/Dostęp do Kursów i Aplikacji - usługa świadczona drogą elektroniczną przez Usługodawcę za pośrednictwem Serwisu, polegająca na dostarczeniu kursów, materiałów edukacyjnych, narzędzi, dzięki którym Usługobiorca może tworzyć materiały marketingowo-reklamowe, np. posty, oferty, wykorzystywane następnie w mediach społecznościowych lub dokonywać innych procesów szczegółowo opisanych przy prezentacji konkretnej Aplikacji w Serwisie.
        <BR/>3. Konsument - osoba fizyczna dokonującą z przedsiębiorcą czynności prawnej niezwiązanej bezpośrednio z jej działalnością gospodarczą lub zawodową.
        <BR/>4. Konsument - przedsiębiorca – osoba fizyczna zawierającą umowę bezpośrednio związaną z jej działalnością gospodarczą, gdy z treści tej umowy wynika, że nie posiada ona dla niej charakteru zawodowego, wynikającego w szczególności z przedmiotu wykonywanej przez nią działalności gospodarczej, udostępnionego na podstawie przepisów o Centralnej Ewidencji i Informacji o Działalności Gospodarczej.
        <BR/>5. Okres testowy – okres, w ciągu którego Usługobiorca ma możliwość zapoznania się z Usługą, za obniżoną ceną. Każdy Usługobiorca może tylko raz skorzystać z Okresu testowego przewidzianego dla Usługi. Długość okresu testowego (3, 7, 10, 14 dni) jest przypisana do danej Usługi dostępnej w Serwisie.
        <BR/>6. Serwis – strona internetowa znajdująca się pod adresem https://edunso.pl/ lub innymi adresami w domenie edunso.pl.
        <BR/>7. Regulamin- nin. regulamin opisujący warunki i zasady świadczenia drogą elektroniczną Usługi.
        <BR/>8. Regulamin Serwisu Edunso.pl/Regulamin ogólny- regulamin określający rodzaje, zakres i warunki świadczenia usług drogą elektroniczną przez Usługodawcę przy pomocy Serwisu.
        <BR/>9. RODO - Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE.
        <BR/>10. Umowa/ Umowa o świadczenie Usług - umowa zawarta z Usługobiorcą w ramach zorganizowanego systemu zawierania umów na odległość, bez jednoczesnej fizycznej obecności stron, z wyłącznym wykorzystaniem jednego lub większej liczby środków porozumiewania się na odległość.
        <BR/>11. Usługobiorca- osoba fizyczna, osoba prawna albo jednostka organizacyjna nieposiadająca osobowości prawnej, która korzysta z usług świadczonych drogą elektroniczną przez Usługodawcę.
        <BR/>12. Usługodawca- AGAI Spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie (00-019 Warszawa, ul. Złota 7/28) zarejestrowaną w rejestrze przedsiębiorców prowadzonym przez Sąd Rejonowy dla Krakowa - Śródmieścia Wydział XI Krajowego Rejestru Sądowego pod nr KRS: 0000847307, NIP 6762582015, REGON 386356611, o kapitale zakładowym w wysokości 5 000 zł.
        <BR/>13. Użytkownik- każda osoba, która w jakikolwiek sposób korzysta z usług oferowanych w Serwisie.

        <BR/><BR/><p><strong>II. Rodzaje i zakres usług objętych Regulaminem</strong></p>

        <BR/>1. Usługodawca, w oparciu o nin. Regulamin świadczy Usługobiorcom Usługi płatne Dostępu do Kursów i Aplikacji za pośrednictwem Serwisu.
        <BR/>2. Rodzaje Kursów i Aplikacji, do których można uzyskać dostęp, ich funkcjonalności, sposób działania, opisy zostały szczegółowo opisane w Serwisie.

        <BR/><BR/><p><strong>Postanowienia szczególne</strong></p>

        <BR/><BR/><p><strong>III. Wymagania techniczne</strong></p>

        <BR/>1. Celem skorzystania z Usługi, Usługobiorca, oprócz wymagań określonych w Regulaminie ogólnym, może fakultatywnie wskazać numer telefonu komórkowego, na który (obok na podany adres e-mail) otrzyma login oraz hasło do indywidualnego Panelu Usługobiorcy w Serwisie

        <BR/><BR/><p><strong>IV. Proces zamówienia Usługi</strong></p>

        <BR/>1. Użytkownik chcąc zamówić Usługę winien: a) wybrać w Serwisie rodzaj/wybraną Kurs/Aplikacji, a następnie opcję „kup teraz”; b) uzupełnić swoje dane osobowe w ramach formularza; c) zaakceptować Regulamin; d) nacisnąć ikonę ,,zamów i zapłać”; e) dokonać opłaty za dany kurs/aplikację.
        <BR/>2. Do zawarcia Umowy o świadczenie Usługi dochodzi w chwili spełnienia przez Usługobiorcę wszystkich czynności opisanych w ust.1.
        <BR/>3. Po dokonaniu czynności wskazanych w ust.1 Usługodawca udostępnia Usługobiorcy login i hasło do Panelu Usługobiorcy, przesyłając je na podany przez Usługobiorcę adres email i numer telefonu (o ile został podany podczas zamówienia Usługi).
        <BR/> 4. Panel Usługobiorcy zostaje automatycznie aktywowany po dokonaniu czynności opisanych w ust.1 przez Usługobiorcę lub w przypadku awarii systemu lub problemów technicznych, po dokonaniu aktywacji ręcznej przez Usługodawcę najdalej w ciągu 3 dni roboczych.
        <BR/>5. Pierwszy dostęp do Panelu Usługobiorcy jest zapewniony przy użyciu loginu i hasła otrzymanego w sposób określony w ust. 3 powyżej. W dalszej kolejności Usługobiorca może zmieniać te dane przy użyciu funkcjonalności w Panelu Usługobiorcy.

        <BR/><BR/><p><strong>V. Proces świadczenia Usługi Dostępu do Kursów i Aplikacji</strong></p>

        <BR/>1. Po zamówieniu Usługi, zgodnie z par. 4 powyżej i po otrzymaniu dostępu do Panelu Usługobiorcy, Usługobiorca otrzymuje dostęp do wybranego Kursu i/lub Aplikacji.
        <BR/>2. Przez cały okres trwania Usługi, Usługobiorca może korzystać z udostępnionej mu Aplikacji lub kursu.
        <BR/>3. Przez cały okres obowiązywania Umowy, Usługobiorca może w każdym czasie, przez nieograniczoną ilość razy korzystać z Kursów i/lub Aplikacji zgodnie z jej założeniami i przeznaczeniem.

        <BR/><BR/><p><strong>VI. Prawa i obowiązki Usługobiorcy</strong></p>

        <BR/>1. Usługobiorca podczas procesu zamawiania Usługi podaje swoje prawdziwe oraz aktualne dane.
        <BR/>2. W przypadku zmiany danych w trakcie trwania umowy Usługobiorca niezwłocznie informuje o tym fakcie Usługodawcę w sposób opisany w §14.
        <BR/>3. Bez uprzedniej zgody Usługodawcy, Usługobiorca nie może przenieść na osobę trzecią praw i obowiązków wynikających z zawartej Umowy o świadczenie Usług.

        <BR/><BR/><p><strong>VII. Prawa i obowiązki Usługodawcy</strong></p>

        <BR/>1. Usługodawca zobowiązuje się do świadczenia Usługi w sposób należyty wynikający z profesjonalnego charakteru świadczenia usług tego rodzaju.
        <BR/>2. Usługodawca zobowiązuje się do utrzymania wysokiego poziomu merytorycznego narzędzi oferowanych w ramach Kursów i Aplikacji.
        <BR/>3. Usługodawca ma prawo wprowadzić bezterminową blokadę Panelu Usługobiorcy w przypadku gdy: a) Usługodawca ustali, że podczas procesu zamawiania Usługi opisanego w §4 Usługobiorca podał nieprawdziwe dane, b) Usługobiorca zalega z zapłatą za świadczenie Usługi za co najmniej jeden okres rozliczeniowy.
        <BR/>4. Usługodawca zastrzega sobie prawo do odmowy świadczenia Usługi bez podania przyczyny. W takim wypadku płatność, o ile została dokonania przez Usługobiorcę zostaje zwrócona, a Usługobiorca jest informowany za pośrednictwem wiadomości e-mail oraz sms (o ile wskazał numer telefonu) o braku możliwości skorzystania z Usługi.

        <BR/><BR/><p><strong>VIII. Własność intelektualna</strong></p>

        <BR/>1. Usługodawca oświadcza, że przysługują mu odpowiednie uprawnienia do Kursów i Aplikacji, w tym jej zawartości obejmującej treści tekstowe oraz graficzne pozwalające na dysponowanie nimi i udostępnianie ich Usługobiorcom.
        <BR/>2. W ramach świadczenia Usługi, Usługodawca zezwala Usługobiorcy, do korzystania z Kursów i Aplikacji jedynie dla celów własnego użytku osobistego.
        <BR/>3. Jakiekolwiek kopiowanie, rozpowszechnianie i inny niż opisany w ust. 2 powyżej sposób korzystania z Kursów i Aplikacji, bez uprzedniej pisemnej zgody Usługodawcy jest niedozwolony.
        <BR/>4. Po zakończeniu świadczenia Usługi, Usługobiorca traci dostęp do Panelu Usługobiorcy, a tym samym Dostęp do Kursów lub/i Aplikacji.

        <BR/><BR/><p><strong>IX. Odpowiedzialność</strong></p>

        <BR/>1. Usługodawca nie odpowiada za skutki oraz następstwa świadczonej Usługi, w tym braku osiągniecia zamierzonych przez Usługobiorcę efektów.
        <BR/>2. Usługodawca nie ponosi odpowiedzialności, w szczególności nie zwraca dokonanych opłat z tytułu zrealizowanej Usługi, w sytuacjach braku satysfakcji i zadowolenia ze strony Usługobiorcy z efektów korzystania z Kursów i/lub Aplikacji.
        <BR/>3. Usługodawca nie ponosi odpowiedzialności w przypadku przerw lub zakłóceń w dostępności i korzystaniu z Usług, jeżeli jest to spowodowane: a) koniecznością naprawy, rozbudowy, modyfikacji lub konserwacji sprzętu lub oprogramowania; b) przyczynami niezależnymi od Usługodawcy (siła wyższa, działania lub zaniechania osób trzecich).

        <BR/><BR/><p><strong>X. Ceny Usług</strong></p>

        <BR/>1. Ceny Usług podane są w Serwisie przy opisie danej Usługi.
        <BR/>2. Wszystkie podane w Serwisie ceny Usług są cenami brutto i zawierają podatek VAT w wysokości wynikającej z obowiązujących przepisów prawa.
        <BR/>3. Wiążąca dla Usługodawcy i Usługobiorcy jest cena podana w Serwisie przy Usłudze w chwili zamówienia Usługi.
        <BR/>4. Usługodawca, zastrzega sobie prawo do zmiany cen Usług, przeprowadzania akcji promocyjnych oraz udzielania rabatów. Odbywa się to z zachowaniem praw nabytych przez Usługobiorców oraz w granicach dopuszczalnych przez obowiązujące przepisy prawa. Zmiany cen nie wpływają na ceny Usług uprzednio zakupionych przez Usługobiorców.

        <BR/><BR/><p><strong> XI. Metody płatności</strong></p>

        <BR/>A. Produkty i usługi subskrypcyjne (autoodnawialne)
        <BR/>1. Usługobiorca, po upływie Okresu testowego i kontynuacji Umowy, zgodnie z par. 13 ust. 1, uiszcza, z góry opłatę z tytułu świadczenia Usług, w odstępach rocznych, a w opisanych poniżej przypadkach, miesięcznych, wynikających z opisu danej Usługi.
        <BR/>2. Dopuszczalne metody płatności, z zastrzeżeniem ust. 4 poniżej, to: a) karta płatnicza za pośrednictwem serwisu Przelewy24pl.; b) przelew za pośrednictwem Dotpay; c) przelew za pośrednictwem Paypal; d) przelew za pośrednictwem PayU; e) przelew za pośrednictwem Imoje.
        <BR/>3. Pierwsza opłata dokonywana przy zawarciu Umowy, o czym mowa w par. 5 realizowana jest tylko i wyłącznie przy użyciu karty płatniczej. W przypadku gdy Usługodawca dokona co najmniej 3 nieudane próby ściągnięcia opłaty rocznej z karty płatniczej, płatność będzie rozłożona na 12 miesięcznych rat, w kwotach określonych w opisie Usługi. Płatności będą ściągane z karty miesięcznie.
        <BR/>4. Usługodawca w pierwszej kolejności ściągnie z karty płatniczej Usługobiorcy (podanej przy procesie zamówienia Usługi), cykliczną opłatę roczną za świadczenie Usług. W przypadku nieudanej próby obciążenia karty, do Usługobiorcy wysyłany jest e-mail informujący o tym fakcie, jednocześnie przekazywane są informacje o alternatywnych metodach płatności. W przypadku braku zapłaty, Usługodawca będzie ściągał z karty płatniczej opłaty miesięczne o których mowa w ust. 3 powyżej.
        <BR/>5. Usługodawca po dokonaniu przez Usługobiorcę opłat opisanych w ust. 1 i 3 wystawia fakturę VAT i przesyła ją na podany przez Usługobiorcę podczas procesu zamówienia Usługi adres email oraz jednocześnie zamieszcza ją w Panelu Usługobiorcy do ew. samodzielnego pobrania przez Usługobiorcę.
        <BR/>6. Usługobiorca wyraża zgodę na przesyłanie przez Usługodawcę faktury VAT jedynie w formie elektronicznej w sposób opisany w ust. 5 powyżej
        <BR/>7. Faktury VAT wystawiane są przez Usługodawcę nie później niż do 15-ego dnia miesiąca następującego po miesiącu, w którym wykonano Usługę. W przypadku Usługobiorców będących Konsumentami, faktury na zgłoszone żądanie wystawiane są zgodnie z obowiązującymi w tym zakresie przepisami.
        <BR/>
        <BR/>B. Produkty i usługi z jednorazową płatnoscią.
        <BR/>1. Usługobiorca uiszcza z góry opłatę z tytułu świadczenia Usług w odstępach rocznych.
        <BR/>2. Dopuszczalne metody płatności to: a) przelew za pośrednictwem Paypal; b) przelew za pośrednictwem PayU; c) przelew za pośrednictwem Imoje.
        <BR/>3. Usługodawca po dokonaniu przez Usługobiorcę opłat opisanych w ust. 1 wystawia fakturę VAT i przesyła ją na podany przez Usługobiorcę podczas procesu zamówienia Usługi adres email.
        <BR/>4. Usługobiorca wyraża zgodę na przesyłanie przez Usługodawcę faktury VAT jedynie w formie elektronicznej w sposób opisany w ust. 3 powyżej
        <BR/>5. Faktury VAT wystawiane są przez Usługodawcę na dane podane przy zakupie nie później niż do 15-ego dnia miesiąca następującego po miesiącu, w którym wykonano Usługę. W przypadku Usługobiorców będących Konsumentami, faktury na zgłoszone żądanie wystawiane są zgodnie z obowiązującymi w tym zakresie przepisami.

        <BR/><BR/><p><strong>XII. Odstąpienie od umowy o świadczenie Usług</strong></p>

        <BR/>1. W przypadku gdy Usługobiorca jest Konsumentem lub Konsumentem-przedsiębiorcą ma prawo odstąpić od umowy o świadczenie Usług w terminie 14 dni od zawarcia umowy bez podania przyczyny.
        <BR/>2. Uprawnienie powyższe nie przysługuje, jeśli Usługobiorca będący Konsumentem lub Konsumentem-przedsiębiorcą wyrazi zgodę i zażąda rozpoczęcia świadczenia Usługi przed upływem 14 dni od dnia zawarcia Umowy.
        <BR/>3. Wzór oświadczenia o odstąpieniu od umowy stanowi załącznik nr 1 do Regulaminu.
        <BR/>4. Usługobiorca będący Konsumentem lub Konsumentem-przedsiębiorcą może wysłać oświadczenie wymienione w ust.1 na adres email Usługodawcy wskazany w §14 lub listownie na adres: AGAI Sp. z o.o. 00-019 Warszawa, ul. Złota 7/28.
        <BR/>5. Uprawnienie powyższe nie przysługuje w przypadku Usługobiorców niebędących Konsumentami lub Konsumentami-przedsiębiorcami.

        <BR/><BR/><p><strong>XIII. Reklamacje</strong></p>

        <BR/>1. Każdemu kupującemu przysługuje prawo zgłoszenia reklamacji, tj:
        <BR/>- konsumentowi (osoba fizyczna nie prowadząca działalności gospodarczej),
        <BR/>- przedsiębiorcy
        <BR/>- przedsiębiorcy dokonującemu zakupu w celach związanych z prowadzoną działalnością jednak nieposiadających dla kupującego charakteru zawodowego
        <BR/>2. Reklamacje należy składać pocztą elektroniczną na adres info@edunso.pl
        <BR/>3. Czas rozpatrzenia reklamacji wynosi 14 dni kalendarzowych.

        <BR/><BR/><p><strong>XIV. Obowiązywanie Umowy</strong></p>

        <BR/>A. Produkty i usługi subskrypcyjne (autoodnawialne)
        <BR/>1. Zawarta w sposób określony w par. 5 umowa o świadczenie Usług obowiązuje przez czas określony - Okres testowy, po którym, w braku odmiennego oświadczenia Usługobiorcy przekształca się w umowę na czas nieokreślony.
        <BR/>2. W Okresie testowym, Usługobiorca może złożyć Usługodawcy oświadczenie o wypowiedzeniu umowy wysyłając mu w tym celu maila na adres: info@edunso.pl W takim wypadku, umowa rozwiązuje się z upływem Okresu testowego.
        <BR/>3. Usługobiorca ma prawo wypowiedzieć umowę zawartą na czas nieokreślony z zachowaniem 1-miesięcznego okresu wypowiedzenia ze skutkiem na koniec miesiąca kalendarzowego. Wypowiedzenie winno być dokonane poprzez wysłanie stosownej wiadomości e-mail na adres: info@edunso.pl. W takim wypadku umowa ulega rozwiązaniu z upływem okresu wypowiedzenia.
        <BR/>4. Usługodawca może wypowiedzieć umowę ze skutkiem natychmiastowym, w przypadku, gdy Usługobiorca narusza postanowienia nin. Regulaminu, w szczególności: zalega z płatnościami za Usługę, za co najmniej 2 pełne okresy płatności.

        <BR/>B. Produkty i usługi z jednorazową płatnoscią.
        <BR/>1. Zawarta w sposób określony w par. 5 umowa o świadczenie Usług obowiązuje przez czas określony
        <BR/>2. Usługobiorca w sposób określony w par. 12 może złożyć Usługodawcy oświadczenie o wypowiedzeniu umowy wysyłając mu w tym celu maila na adres: info@edunso.pl
        <BR/>3. Usługodawca może wypowiedzieć umowę ze skutkiem natychmiastowym, w przypadku, gdy Usługobiorca narusza postanowienia nin. Regulaminu, w szczególności: wykorzystuje narzędzia w sposób niezgodny z ich przeznaczeniem lub w sposób który narusza obowiązujące w Polsce prawo.


        <BR/><BR/><p><strong>XV. Kontakt Usługodawcy i Usługobiorcy</strong></p>

        <BR/>We wszystkich sprawach związanych z realizacją Usługi Usługobiorca może kontaktować się z Usługobiorcą za pośrednictwem adresu e-mail: info@edunso.pl

        <BR/><BR/><p><strong>XVI. Dane osobowe</strong></p>

        <BR/>1. Administratorem danych osobowych Usługobiorców przetwarzanych w zw. ze świadczoną Usługą jest Usługodawca.
        <BR/>2. Administrator gwarantuje i zapewnia, że przetwarzanie danych osobowych w ramach zawartej Umowy i świadczonej w zw. z nią Usługą odbywa się zgodnie z zapisami RODO, w szczególności Administrator stosuje odpowiednie środki techniczne i organizacyjne zapewniające bezpieczeństwo przetwarzania.
        <BR/>3. Administrator spełnia obowiązek informacyjny względem Usługobiorców w momencie zbierania od nich danych przy zawieraniu umowy, o czym mowa w par. 5. Jednocześnie odpowiednia nota informacyjna jest umieszczona w Panelu Usługobiorcy, który ma możliwość się z nią zapoznać w każdym czasie.
        <BR/>4. Szczegółowe informacje na temat przetwarzania danych osobowych dostępne są też na stronie edunso.pl w zakładce „Polityka prywatności”.

        <BR/><BR/><p><strong>XVII. Postanowienia końcowe</strong></p>

        <BR/>1. Niniejszy Regulamin obowiązuje od dnia opublikowania go w Serwisie.
        <BR/>2. Usługodawca zastrzega sobie możliwość zmiany Regulaminu, w zakresie dopuszczanym przez obowiązujące przepisy, w szczególności modyfikacje mogą być wynikiem zmian obowiązujących przepisów, przy czym zmiany Regulaminu nie naruszają praw nabytych przez Usługobiorców.
        <BR/>3. Usługodawca za każdym razem poinformuje Usługobiorców o zmianie Regulaminu umieszczając odpowiedni komunikat w Serwisie oraz wysyłając w tym celu wiadomość e-mail. Zmiany Regulaminu wchodzą w życie po upływie 14 dni od dnia poinformowania Użytkownika o wprowadzonych zmianach w sposób wskazany powyżej. Jeżeli Usługobiorca nie akceptuje wprowadzonych zmian powinien wypowiedzieć Umowę.
        <BR/>4. Regulamin jest na stałe dostępny nieodpłatnie w Serwisie, na stronie https://edunso.pl/regulamin w formie, która umożliwia jego pozyskanie, odtwarzanie i utrwalanie za pomocą systemu teleinformatycznego, którym posługuje się Użytkownik.
        <BR/>5. W sprawach nieuregulowanych w niniejszym Regulaminie mają zastosowanie postanowienia Regulaminu Serwisu Edunso.pl oraz obowiązujące przepisy prawa polskiego.
        <BR/>6. Wszelkie spory związane z zawartą Umową o świadczenie Usług rozstrzygane będą przez właściwe sądy polskie.
        <BR/>7. W przypadku Usługobiorcy, który jest Konsumentem istnieje możliwość skorzystania z pozasądowego sposobu rozstrzygania sporów. Szczegółowe informacje w tym przedmiocie dostępne są w siedzibach oraz na stronach internetowych powiatowych (miejskich) rzeczników konsumentów, organizacji społecznych, do których zadań statutowych należy ochrona konsumentów (Federacja Konsumentów, Stowarzyszenie Konsumentów Polskich), Wojewódzkich Inspektoratów Inspekcji Handlowej.

        <BR/><BR/>
        <A href="https://login.edunso.pl/docs/OdstapienieOdUmowy.pdf" target="_blank"><B>Załącznik nr 1. Formularz odstąpienia od umowy</B></A>

      </div>

    </div>

    <Footer/>
  </div>
</template>

<script>
import MiddleBarStaticPages from '../../../elements/MiddleBarStaticPages';
import Footer from '../../../elements/Footer';
import NavBar from "@/elements/Navbar/index.vue";

export default {
  name: 'Regulamin',
  components: {
    NavBar,
    MiddleBarStaticPages,
    Footer
  },
  data() {
    return {
      user: {
        id: null,
        permissions: []
      }
    }
  },

  created() {

    this.axios.get(window.API_URL + "user", { withCredentials: true }).then((response) => {

      let data = response.data

      if (data.userId) {

        this.user.id = data.userId
        this.user.permissions = data.permissions

      }

    });

    window.scrollTo({ top: 0, left: 0 });

  },
}
</script>

 